import React from "react";
import Select from "react-select";

const MultiSelectOption = ({ labelFiled,value,type,fullData,handleChange }) => {
  const optionsDriverLicense = [{ value: "A2", label: "A2" }, { value: "B", label: "B" }, { value: "B1", label: "B1" }, { value: "C", label: "C" }, { value: "D", label: "D" }];
  const optionsJobWay = [{ value: 1, label: "Tam Zamanlı" }, { value: 2, label: "Yarım Zamanlı" }, { value:  3, label: "Proje Bazlı" }, { value:4, label: "Geçici" }, { value: 5, label: "Stajyer" }];

  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#081F43' : 'white',
      color:  state.isSelected ? 'white' : '#081F43',
      menu: base => ({
        ...base,
        zIndex: 100,
        backgroundColor:'red'
      }),
    })
  }

  return (
    type == "blog" ?
    <div className="relative">
      <Select
        isMulti
        defaultValue={value}
        value={value}
        onChange={e => handleChange(e,"blog")}

        options={fullData}
        placeholder="Blog Seçiniz .."
        id="multi-select"
        styles={CustomStyle}
        className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer "
      />
      <label
        htmlFor="multi-select"
        className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
      >{labelFiled}
      </label>
    </div> : 
    type == "gram" ?
    <div className="relative">
      <Select
        isMulti
        defaultValue={value}
        value={value}
        onChange={e => handleChange(e,"gram")}

        options={fullData}
        placeholder="Ürün Birimi Seçiniz .."
        id="multi-select"
        styles={CustomStyle}
        className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer "
      />
      <label
        htmlFor="multi-select"
        className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
      >{labelFiled}
      </label>
    </div> : 
    type == "product" ?
    <div className="relative">
      <Select
        isMulti
        defaultValue={value}
        value={value}
        onChange={e => handleChange(e,"product")}

        options={fullData}
        placeholder="Ürün Seçiniz .."
        id="multi-select"
        styles={CustomStyle}
        className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer "
      />
      <label
        htmlFor="multi-select"
        className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
      >{labelFiled}
      </label>
    </div> : 
    type == "teacher" ?
    <div className="relative">
      <Select
        isMulti
        defaultValue={value}
        value={value}
        onChange={e => handleChange(e,"teacher")}

        options={fullData}
        placeholder="Öğretmen Seçiniz .."
        id="multi-select"
        styles={CustomStyle}
        className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer  "
      />
      <label
        htmlFor="multi-select"
        className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
      >{labelFiled}
      </label>
    </div> : 
    type == "jobwayout" ?
    <div className="relative">
      <Select
        isMulti
        defaultValue={value}
        options={optionsJobWay}
        placeholder="Çalışma Şeklini Seçiniz..."
        id="multi-select"
        value={value}
        onChange={e => handleChange(e,"jobwayout")}
        styles={CustomStyle}
        className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer  "
      />
      <label
        htmlFor="multi-select"
        className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
      >{labelFiled}
      </label>
    </div> : 
    type == "driverlicense" ?
    <div className="relative">
      <Select
        styles={CustomStyle}
        isMulti
        value={value}
        onChange={e => handleChange(e,"driverlicense")}
        defaultValue={value}
        options={optionsDriverLicense}
        placeholder="Ehliyet Durumunu Seçiniz..."
        id="multi-select"
        className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer  "
      />
      <label
        htmlFor="multi-select"
        className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
      >{labelFiled}
      </label>
    </div> : 
     type == "jobs" ?
     <div className="relative">
       <Select
               styles={CustomStyle}
         isMulti
         defaultValue={value}
         options={fullData}
         placeholder="İş Seçiniz..."
         id="multi-select"
         className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer  "
       />
       <label
         htmlFor="multi-select"
         className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
       >{labelFiled}
       </label>
     </div>:
        <div className="relative">
        <Select
                styles={CustomStyle}
          isMulti
          options={optionsDriverLicense}
          placeholder="Ehliyet Durumunu Seçiniz..."
          id="multi-select"
          className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer  "
        />
        <label
          htmlFor="multi-select"
          className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
        >{labelFiled}
        </label>
      </div> 
  );
};

export default MultiSelectOption;
