import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';
import {Base64} from 'js-base64';
import MultiSelectOption from "../MultiSelectOption";
import "jspdf-autotable";
import * as XLSX from 'xlsx';

import {
  addRecipes as onAddRecipes,
  updateRecipes as onUpdateRecipes,
  getRecipes as onGetRecipes,
  deleteRecipes as onDeleteRecipes
} from "store/contacts/actions";


import axios from 'axios';


import {
  Name
}
  from "./EcommerceOrderCol";
  import 'react-dropzone-uploader/dist/styles.css'
  import Dropzone from 'react-dropzone-uploader';

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function EcommerceOrder() {

  //meta title
  document.title = "Tarifler | Buhara - Admin Paneli";

  const [getFileNameMobile, setFileNameMobile] = useState(null);
  const  [getFileNameLogo, setFileNameLogo] = useState(null);
  const [getProductList, setProductList] = useState();
  const [getSelectProduct, setSelectProduct] = useState([]);

  const [getSelectGramList, setSelectGramList] = useState([]);
  const [getGramList, setGramList] = useState([]);
  const [getBlogList, setBlogList] = useState([]);

  const [getIsAddMaking, setIsAddMaking] = useState(false);

  const [getMakingList, setMakingList] = useState([]);
  const [getCountStep, setCountStep] = useState(1);

  
  
  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://buharaadmin.stechomeyazilim.info:8099/uploadfile', body }
  }
   
  // handle the status of the file upload
  const handleChangeStatusLogo = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileNameLogo(result.file.filename)

        }
      }
    }
  }



  const _getGramList = async () => {
    setProductList([])
    await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getProductsUnit/select/`)
    .then((res) => {
      res.data.map((item, index) => (

        setGramList(oldArray => [...oldArray,{label:item.Products_Units_Text,value:item.ID}] )

      ))

    })
  }
  
  const _getProductList = async () => {
    setProductList([])
    await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getProductList/select/`)
    .then((res) => {
      res.data.map((item, index) => (

        setProductList(oldArray => [...oldArray,{label:item.Products_Name,value:item.ID}] )

      ))

    })
  }

  const _getBlogList = async () => {
    setBlogList([])
    await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getBlog/select/`)
    .then((res) => {
      res.data.map((item, index) => (

        setBlogList(oldArray => [...oldArray,{label:item.Blog_Title,value:item.ID}] )

      ))

    })
  }


    // handle the status of the file upload
    const handleChangeStatusMobile = ({ xhr }) => {
      if (xhr) {
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            const result = JSON.parse(xhr.response);
            setFileNameMobile(result.file.filename)
  
          }
        }
      }
    }
  

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getMakingText, setMakingText] = useState(null);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [getOrderProduct, setOrderProduct] = useState(null);
  const [getOrderBlog, setOrderBlog] = useState(null);

  const [getEditSlider, setEditSlider] = useState(null);
  const [getIsEditAddProduct, setIsEditAddProduct] = useState(false);

  const [getIsAddBlog, setIsAddBlog] = useState(false);
  const [getSelectBlog, setSelectBlog] = useState([]);

  
  const [getIsActiveStory, setIsActiveStory] = useState(false);

  const validation2 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      units: (getEditSlider && getEditSlider.Products_Unit_Input) || '',
    },
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateSlider = {
          ID: order ? order.ID : 0,
          Recipes_Title: values.title,
          Recipes_Desc: values.longDesc,
          Recipes_Image : getFileNameLogo ,
          Recipes_ShortText : values.shortDesc,
         // Recipes_Category_ID:
          Recipes_Cooking_Time:values.cookingTime,
        //  Recipes_Trend:
          Recipes_IsStory:getIsActiveStory
        };
        // update order
        dispatch(onUpdateRecipes(updateSlider));
        validation.resetForm();
      } else {
        const newOrder = {
          Recipes_Title: values["title"],
          Recipes_Desc: values["longDesc"],
          Recipes_Image : getFileNameLogo ,
          Recipes_ShortText : values["shortDesc"],
         // Recipes_Category_ID:
          Recipes_Cooking_Time:values["cookingTime"],
        //  Recipes_Trend:
          Recipes_IsStory:getIsActiveStory
        };

        // save new order
        dispatch(onAddRecipes(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });
  

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      recipesName: (getEditSlider && getEditSlider.Recipes_Title) || '',
      shortDesc: (getEditSlider && getEditSlider.Recipes_ShortText) || '',
      cookingTime: (getEditSlider && getEditSlider.Recipes_Cooking_Time) || '',
      prepationTime: (getEditSlider && getEditSlider.Recipes_Preparation_Time) || '',
      longDesc:(getEditSlider && getEditSlider.Recipes_Desc) || '',
    },
    validationSchema: Yup.object({
      recipesName: Yup.string().required("Tarif Başlığını Giriniz"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateSlider = {
          ID: order ? order.ID : 0,
          Recipes_Title: values.recipesName,
          Recipes_Desc: values["longDesc"],
          Recipes_Image : getFileNameLogo ,
          Recipes_Preparation_Time : values.prepationTime,
          Recipes_ShortText : values.shortDesc,
         /// Recipes_Category_ID : getFileNameMobile,
          Recipes_Cooking_Time : values.cookingTime,
          //Recipes_Trend : getFileNameMobile,
          Recipes_IsStory : getIsActiveStory,

        };
        // update order
        dispatch(onUpdateRecipes(updateSlider));
        validation.resetForm();
      } else {
        const newOrder = {
          Recipes_Title: values["recipesName"],
           Recipes_Desc: values["longDesc"],
            Recipes_Image : getFileNameLogo ,
            Recipes_Preparation_Time : values["prepationTime"],
            Recipes_ShortText : values["shortDesc"],
           /// Recipes_Category_ID : getFileNameMobile,
            Recipes_Cooking_Time : values["cookingTime"],
            //Recipes_Trend : getFileNameMobile,
            Recipes_IsStory : getIsActiveStory,
        };

        // save new order
        dispatch(onAddRecipes(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { orders } = useSelector(state => ({
    orders: state.contacts.recipesR,
  }));

  useEffect(() => {
   if (orders && !orders.length) {
      dispatch(onGetRecipes());
      setIsEdit(false);
    }
  }, [dispatch, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  
  const handleChangeMaking = async(e) => {
    try{

      setMakingText(e.target.value)
    }catch (err) {
      console.log("handleChangeMaking",err)
    }
  }

  const handleChangeProduct = async(e) => {
    try{


      setProductText(e.target.value)
    }catch (err) {
      console.log("handleChangeProduct",err)
    }
  }
  
  const _updateProductList= async() => {
    try{
      
      const updateSlider = {
        Products_Name: getMakingText,
     }

     console.log("getProductDetail",getProductDetail)
     console.log("getProductCheck",getProductCheck)



     if(getProductCheck == 1){
      await axios.patch(`https://buharaapi.stechomeyazilim.info:5210/patchProduct/${getProductDetail.ID}`, updateSlider).then(async(res) => {
 
        console.log("klsdklfm",res)
        dispatch(onGetRecipes());
 
  
        setIsAddMaking(false);
        setModal(false);
        setMakinModal(false)
        setProductModal(false)
 
        })
 
     }else{
      await axios.patch(`https://buharaapi.stechomeyazilim.info:5210/patchProduct2/${getProductDetail.ID}`, updateSlider).then(async(res) => {
 
        console.log("klsdklfm",res)
        dispatch(onGetRecipes());
 
  
        setIsAddMaking(false);
        setModal(false);
        setMakinModal(false)
        setProductModal(false)
 
        })
 
     }
      
 
 }catch (err) {
     console.log("_updateMakingList",err)
   }
      }

   const _deleteRecipesProduct = async() => {
        try{
        
        
           axios.delete(`https://buharaapi.stechomeyazilim.info:5210/deleteRecipesProduct/${getProductDetail.ID}`).then(async(res) => {
        
          
        
              dispatch(onGetRecipes());
    
     
           setIsAddMaking(false);
           setModal(false);
           setMakinModal(false)
           setProductModal(false)

              })
          
    
        
        }catch (err) {
            console.log("postBlogInRecipespostBlogInRecipes",err)
          }
        
          }


  const _updateMakingList = async() => {
    try{
      
      const updateSlider = {
        Recipes_Making_Title: getMakingText,
     }

     console.log("lsmdlşf1",getMakingDetail)
     console.log("lsmdlşf2",getSelectProduct)

       await axios.patch(`https://buharaapi.stechomeyazilim.info:5210/patchRecipesMaking/${getMakingDetail.ID}`, updateSlider).then(async(res) => {
 
       console.log("klsdklfm",res)
       dispatch(onGetRecipes());

 
       setIsAddMaking(false);
       setModal(false);
       setMakinModal(false)
       })

 
 }catch (err) {
     console.log("_updateMakingList",err)
   }
      }

      
  const _deleteRecipesBlog = async(ID) => {
        try{
        
        
           axios.delete(`https://buharaapi.stechomeyazilim.info:5210/deleteRecipesBlog/${ID}`).then(async(res) => {
        
          
        
              dispatch(onGetRecipes());
    
     
           setIsAddMaking(false);
           setModal(false);
           setMakinModal(false)
              })
          
    
        
        }catch (err) {
            console.log("postBlogInRecipespostBlogInRecipes",err)
          }
        
          }

  const _deleteRecipesMaking = async() => {
    try{
    
    
       axios.delete(`https://buharaapi.stechomeyazilim.info:5210/deleteRecipesMaking/${getMakingDetail.ID}`).then(async(res) => {
    
      
    
          dispatch(onGetRecipes());

 
       setIsAddMaking(false);
       setModal(false);
       setMakinModal(false)
          })
      

    
    }catch (err) {
        console.log("postBlogInRecipespostBlogInRecipes",err)
      }
    
      }
  const _addMakingList = async() => {
    try{
    
      if(getSelectGramList.length > 0){

        getSelectGramList.map(async(item, index) => {
          let data = {
            Product_ID:item.value,
            Products_Unit_ID:item.value,
            Products_Unit_Input:item.value,
            Recipes_ID:getOrderBlog.ID,
          }    
              
          await axios.post(`https://buharaapi.stechomeyazilim.info:5210/postRecipesInProducts/insert`, data).then(async(res) => {
    
          dispatch(onGetRecipes());
    
          toggleAddMaking()
          })
       })
      
      }else{
        showToast(false)
      }
     /* axios.delete(`https://buharaapi.stechomeyazilim.info:5210/deleteRecipes/${ID}`).then(async(res) => {
    
      console.log("delete1",res)
    }) */
    
    }catch (err) {
        console.log("postBlogInRecipespostBlogInRecipes",err)
      }
    
      }

  const _addProducts = async() => {
    try{
    
      if(getSelectGramList.length > 0){

        getSelectGramList.map(async(item, index) => {

          let data = {
            Product_ID:item.value,
           // Products_Unit_ID:item.value,
            Products_Unit_Input:item.value,
            Recipes_ID:getOrderProduct.ID,
            Products2_ID:null
          }    
              
          await axios.post(`https://buharaapi.stechomeyazilim.info:5210/postRecipesInProducts/insert`, data).then(async(res) => {
    
            console.log("ldsfmş",res)
          dispatch(onGetRecipes());
    
          toggleAddProduct()
          })
       })
      
      }else{
        showToast(false)
      }
     /* axios.delete(`https://buharaapi.stechomeyazilim.info:5210/deleteRecipes/${ID}`).then(async(res) => {
    
      console.log("delete1",res)
    }) */
    
    }catch (err) {
        console.log("_addProducts",err)
      }
    
      }

  const postBlogInRecipes = async() => {
try{

  if(getSelectBlog.length > 0){
    getSelectBlog.map(async(item, index) => {
      let data = {
        Recipes_ID:getOrderBlog.ID,
        Blog_ID:item.value
      }    
      
      await axios.post(`https://buharaapi.stechomeyazilim.info:5210/postBlogInRecipes/insert`, data).then(async(res) => {

      dispatch(onGetRecipes());

      toggleAddBlog()
      })
   })
  
  }else{
    showToast(false)
  }
 /* axios.delete(`https://buharaapi.stechomeyazilim.info:5210/deleteRecipes/${ID}`).then(async(res) => {

  console.log("delete1",res)
}) */

}catch (err) {
    console.log("postBlogInRecipespostBlogInRecipes",err)
  }

  }

  
  const toggleProduct = () => {
    if (getProductModal) {
      setModal(false);
      setProductModal(false);
      setMakingList(null);
    } else {
      setProductModal(true);
    }
  };

  const toggleMaking = () => {
    if (getMakinModal) {
      setModal(false);
      setMakinModal(false);
      setMakingList(null);
    } else {
      setMakinModal(true);
    }
  };
  const toggleAddMaking = () => {

    console.log("lsndlkf",getMakingDetail.Recipes_Making_Title)
   setMakingText(getMakingDetail.Recipes_Making_Title)
   setModal(false);

    if (getIsAddMaking) {
    
      setIsAddMaking(false);
      setMakingList(null);
    } else {
      setIsAddMaking(true);
    }
  };

  const toggleAddBlog = () => {
    if (getIsAddBlog) {
      setModal(false);
      setIsAddBlog(false);
      setOrderProduct(null);
    } else {
      setIsAddBlog(true);
    }
  };

  const toggleAddProduct = () => {
    if (getIsEditAddProduct) {
      setModal(false);
      setIsEditAddProduct(false);
      setOrderProduct(null);
    } else {
      setIsEditAddProduct(true);
    }
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleAddBlog = arg => {
    const order = arg;
    setOrderBlog(order);
    setIsAddBlog(true);
    setSelectBlog([])

    _getBlogList()

    
    if(order.Recipes_Blog.length>0){

      order.Recipes_Blog.map(async(item, index) => {
        setSelectBlog(oldArray => [...oldArray,{label:item.Blog.Blog_Title,value:item.Blog.ID}] )
     })
    }

    toggleAddBlog();
  };

  
  
  const handleAddMaking = arg => {
    const order = arg;
    setMakingList(order.Recipes_Making);
    setIsAddMaking(true);
  }


  const handleAddProduct = arg => {
    const order = arg;
    setOrderProduct(order);
    setIsEditAddProduct(true);

    _getProductList();
    _getGramList();

    if(order.Recipes_Products.length>0){

      order.Recipes_Products.map(async(item, index) => {
        setProductList(oldArray => [...oldArray,{label:item.Products != null ? item.Products.Products_Name :  item.Products2.Products_Name,value:item.Products != null ? item.Products.ID : item.Products2.ID}] )
     })
    }

    toggleAddProduct()
  }


  const handleOrderClick = arg => {
    const order = arg;
    setEditSlider(order)
    setOrder(order);
    setIsEdit(true);

    setIsActiveStory(order.Recipes_IsStory)
    setFileNameLogo(order.Recipes_Image)
    setFileNameMobile(order.Recipes_EngImg)
    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [getMakinModal, setMakinModal] = useState(false);
  const [getMakingDetail, setMakingDetail] = useState(null);

  const [getProductModal, setProductModal] = useState(false);
  const [getProductDetail, setProductDetail] = useState(null);
  const [getProductText, setProductText] = useState(null);
  const [getProductCheck, setProductCheck] = useState(null);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };
  
  const handleRecipesMaking = (item) => {
   
      setMakinModal(true);
      setMakingDetail(item)
      setMakingText(item.Recipes_Making_Title)
  };


    
  const handleRecipesProduct = (item) => {
   
      setProductModal(true);
      setProductDetail(item)

      if(item.Products != null){
        setProductText(item.Products.Products_Name)
        setProductCheck(1)
      }

      if(item.Products2 != null){
        setProductText(item.Products2.Products_Name)
        setProductCheck(2)
      }

      
  };


  const handleDeleteOrder = () => {
    if (order && order.ID) {
      dispatch(onDeleteRecipes(order.ID));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Tarif Görseli",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.Recipes_Image ? (
              <div className="avatar-xl">
               {cellProps.Recipes_Title != null ? <span className="avatar-title rounded-circle">
                  {cellProps.Recipes_Title.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className=" avatar-xl"
                  src={'https://buharasubdomain.stechomeyazilim.info/' +  cellProps.Recipes_Image}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
    
      {
        Header: 'Tarif Adı',
        accessor: 'Recipes_Title',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },

      {
        Header: 'Tarif Açıklama',
        accessor: 'Recipes_Desc',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },

    
      {
        Header: 'Tarif Nasıl Yapılır?',
        accessor: 'Recipes_Making',
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
          
            {cellProps.Recipes_Making == null ? null : cellProps.Recipes_Making.length > 0 ?
            
            cellProps.Recipes_Making.map((item, index) => {
              return(
               item.Recipes_Making_Title != null ? 
              <div>
               <Link
               to="#"
               className="text-success"
               onClick={() => {
               
                 handleRecipesMaking(item);
               }}
               key={index}
             >   
               
               <a>
                  {item.Recipes_Making_Sort} - 
                </a> 

                <a>
                  {item.Recipes_Making_Title}
                </a> 

                <a> </a>

             
               
                </Link>
                </div>: null
             )}
            ) :  <div>
               
            <a>
             İçerik Bulunmamaktadır!
            </a> 

            </div>}
          </>
        ),
      },

      {
        Header: 'Tarif Ürünleri',
        accessor: 'Recipes_Products',
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {cellProps.Recipes_Products == null ? null : cellProps.Recipes_Products.length > 0 ?
            
            cellProps.Recipes_Products.map((item, index) => {
              return(
               
                item.Products != null  &&  item.Products.Products_Name != null ?
                <div key={index} >
                <Link
               to="#"
               className="text-success"
               onClick={() => {
               
                handleRecipesProduct(item);
               }}
               key={index}
             >   
                <a>
                  {item.Products_Unit_Input}
                </a> 

              

                <a>
                  {item.Products_Units_Text}
                </a> 

              

                <a>
                  {item.Products.Products_Name}
                </a> 
            
              
                </Link>
                </div>:  item.Products2.Products_Name != null &&  item.Products2 != null ? 
                <div key={index} >
                <Link
               to="#"
               className="text-success"
               onClick={() => {
               
                 handleRecipesProduct(item);
               }}
               key={index}
             >   

<a>
                  {item.Products_Unit_Input}
                </a> 

                <a>
                  {item.Recipe_Products_GR}
                </a> 

                <a>
                {item.Products2.Products2_UnitText}
                </a> 

                
              

                <a>
                  {item.Products2.Products_Name}
                </a> 
            
              
              </Link>
                </div> : null
             )}
            ) :  <div>
               
            <a>
             İçerik Bulunmamaktadır!
            </a> 

            </div>}
          </>
        ),
      },
      {
        Header: 'Tarif Blogları',
        accessor: 'Recipes_Blog',
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {cellProps.Recipes_Blog == null ? null : cellProps.Recipes_Blog.length > 0 ?
            cellProps.Recipes_Blog.map((item, index) => {
              return(
               item.Blog.Blog_Title != null ? 
                <div key={index} >
               
                <a>
                  {item.Blog.Blog_Title}
                </a> 

                <button
                        type="submit"
                        onClick={()=> _deleteRecipesBlog(item.ID)}
                        className="btn btn-danger save-user"
                      >
                        Sil
                      </button>

                </div>: null
             )}
            ) :  <div>
               
            <a>
             İçerik Bulunmamaktadır!
            </a> 

            </div>}
          </>
        ),
      },
      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
               <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleAddBlog(orderData);
                }}
              >              
                  Blog Ekle
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleAddMaking(orderData);
                }}
              >              
                  Nasıl Yapılır?
              </Link>

               <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleAddProduct(orderData);
                }}
              >              
                  Ürün Ekle
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  function showToast(type) {

    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Giriş İşleminiz Gerçekleşmiştir!"
    } else{
       ele = "error"
       message = "Gramaj Eklemeniz için Ürün Eklemelisiniz!"
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  const handleChangeGramList= async (val,type) => {
    if(getSelectProduct.length > getSelectGramList.length){
      setSelectGramList(val)
    }else{
      showToast(false)
    }
           
    }

    
    const handleChangeBlog= async (val,type) => {
          setSelectBlog(val)
      }

  const handleChangeProductList= async (val,type) => {
    

      if(type == "product"){
        setSelectProduct(val)
      }

      if(type == "gram"){
        setSelectGramList(val)
      }
      
    }

    function countUP() {
      setCountStep(getCountStep+1);
    }
  
    function countDown() {
      setCountStep(getCountStep-1);
    }
  

    const arrayBufferToBinaryString = (buffer) => {
      const binaryArray = new Uint8Array(buffer);
      const binaryString = binaryArray.reduce((data, byte) => {
        return data + String.fromCharCode(byte);
      }, '');
      return binaryString;
    };

    
    const filterSequentialRows = (data) => {
      // Sadece belirli numaralı satırları içeren array oluştur
      const validIds = Array.from({ length: 10 }, (_, i) => String(i + 1));
  
      // Veriyi filtrele
      const filteredRows = data.filter(row => {
        // Her bir satır için sadece belirli sıralı numaralı (örneğin, 1'den 10'a kadar) satırları al
        for (let id of validIds) {
          // Eğer ID sütunu belirli numaralı ise (örneğin, '1', '2', '3' vb.)
          if (row.hasOwnProperty(id)) {
            return true;
          }
        }
        return false;
      });
  
      return filteredRows;
    };
    
    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const binaryString = arrayBufferToBinaryString(event.target.result);
        const workbook = XLSX.read(binaryString, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        
        // Assuming the first row contains headers
        const headers = data[0];
  
        
      
  
        const formattedData = data.slice(1).map((row) => {
          const rowData = {};
          row.forEach((cell, index) => {
            rowData[headers[index]] = cell;
          });
          return rowData;
        });
  
  
        formattedData.map(async(row) => {

          console.log("lmsmdlf",row.katID)
    
          const newOrder = {
            Recipes_Title: row.tarifadı,
            Recipes_Desc: row.tarifaçıklaması,
            Recipes_Preparation_Time:row.hazırlamasüresi,
            Recipes_Cooking_Time:row.pişirmesüresi,
            Recipes_Category_ID: parseInt(row.katID),
            Recipes_IsStory:false,
            Recipes_Trend:false,
            Recipes_EngTitle:row.engTitle
          };
          // save new order
        
          await axios.post(`https://buharaapi.stechomeyazilim.info:5210/addRecipes/insert`, newOrder).then(async(res) => {
            
          
            await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getProducts2/select/`)
            .then((res1) => {

              res1.data.map(async(item1, index) => {
        
                for (let i = 1; i <= 11; i++) {

                if(item1.Products2_Code ==  row["id" + i].toString()){
        
                  const pDetail = {
                    Products2_ID: item1.ID,
                    Products_Unit_ID: item1.Products_Units_ID,
                    Recipes_ID: res.data.value[0].ID,
                    Products_Unit_Input: parseFloat(row["miktar" + i]),
                    Recipe_Products_GR :  parseFloat(row["miktar" + i]) * parseInt(item1.Products_Amount)
                  }
          
                  console.log("pDetail12321",pDetail)

                  await axios.post(`https://buharaapi.stechomeyazilim.info:5210/addRecipesProducts/insert`, pDetail).then(async(resD) => {
              
                    console.log("sdf123ml1sd",resD)
                  })
        
                }
               }
              })
            })
                     
        
              for (let i = 1; i <= 11; i++) {
    
                const cellValue = row[i.toString()];
                if (cellValue !== null && cellValue !== '') {
                  const newOrder = {
                    Recipes_Making_Title: row[i].toString(),
                    Recipes_Making_Sort: i,
                    Recipes_ID: res.data.value[0].ID,
                   
                    // Buraya başka eklemek istediğiniz alanları da ekleyebilirsiniz
                  };
      
                  await axios.post(`https://buharaapi.stechomeyazilim.info:5210/postRecipesMaking/insert`, newOrder).then(async(res) => {
              
                  
                  })
                }
             
      
          
         
              
            }
        


          })

  
          setTimeout(() =>   dispatch(onGetRecipes()), 1000)
  


  
  
       
      
        
  
        })
        
      };
  
      reader.readAsArrayBuffer(file);
    }
  

    const handleClick = () => {
      document.getElementById('fileInput').click();
    };

  
    
  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Yönetim" breadcrumbItem="Tarifler" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>

                <button 
                color="success"
      className="btn-rounded  mb-2 me-2" 
      onClick={handleClick}>Excel Dosyasını Yükle</button>
      <input
        id="fileInput"
        type="file"
        accept=".xlsx, .xls"
        style={{ display: 'none' }}
        
        onChange={handleFileUpload}
      />


                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    isAddRecipes={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          <Modal isOpen={getMakinModal} toggle={toggleMaking}>
            <ModalHeader toggle={toggleMaking} tag="h4">
              {!!getMakinModal ? "Nasıl Yapılır Düzenle" : "Nasıl Yapılır Ekle"}
            </ModalHeader>
            <ModalBody>
             
                <Row>
                  <Col>

                  
                  
                 { getMakingDetail != null ?        
      <Input
        name="units"
        type="text"
        placeholder="Nasıl Yapılır?"
        onChange={handleChangeMaking}
        value={getMakingText}
       
      /> : null}
  

                    <div className="text-end">

                    <button
                        type="submit"
                        onClick={()=> _deleteRecipesMaking()}
                        className="btn btn-danger save-user"
                      >
                        Sil
                      </button>

                      <button
                        type="submit"
                        onClick={()=> _updateMakingList()}
                        className="btn btn-success save-user"
                      >
                        Güncelle
                      </button>
                    </div>
                  </Col>
                </Row>
            
            </ModalBody>
          </Modal>
          

          <Modal isOpen={getProductModal} toggle={toggleProduct}>
            <ModalHeader toggle={toggleProduct} tag="h4">
              {!!getProductModal ? "Ürün Düzenle" : "Ürün Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                 
                }}
              >

                <Row>
                  <Col>

                  
                  
                 { getProductDetail != null ?        
      <Input
        name="units"
        type="text"
        placeholder="Ürün ?"
        onChange={handleChangeProduct}
        value={getProductText}
       
      /> : null}
  

                    <div className="text-end">

                    <button
                        type="submit"
                        onClick={()=> _deleteRecipesProduct()}
                        className="btn btn-danger save-user"
                      >
                        Sil
                      </button>

                      <button
                        type="submit"
                        onClick={()=> _updateProductList()}
                        className="btn btn-success save-user"
                      >
                        Güncelle
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
          <Modal isOpen={getIsAddMaking} toggle={toggleAddMaking}>
            <ModalHeader toggle={toggleAddMaking} tag="h4">
              {!!getIsAddMaking ? "Nasıl Yapılır Düzenle" : "Nasıl Yapılır"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                 
                }}
              >
                
                <div style={{ width: "120px" }}>
                                <div className="input-group">
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-primary"
                                      onClick={() => {
                                        countDown();
                                      }}>-</button>
                                  </div>

                                
                                  <Input
                                    type="text"
                                    name="demo_vertical"
                                    readOnly
                                    value={getCountStep}
                                  />

                                    <div className="input-group-prepend">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() => {
                                        countUP();
                                      }}>+
                                    </button>
                                  </div>
                                 
                                </div>
                              </div>
                {
                 Array(getCountStep).fill().map(function (item, index) {
                  return <div key={index} className="mb-3">
      <Label className="form-label">{index + 1 }.</Label>
      <Input
        name="units"
        type="text"
        placeholder="Birim"
        onChange={validation2.handleChange}
        onBlur={validation2.handleBlur}
        value={validation2.values.units || ""}
        invalid={
          validation2.touched.units && validation2.errors.units ? true : false
        }
      />
      {validation2.touched.units && validation2.errors.units ? (
        <FormFeedback type="invalid">{validation2.errors.units}</FormFeedback>
      ) : null}
    </div>  
    })
  }

                                   

                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        onClick={()=> _addMakingList()}
                        className="btn btn-success save-user"
                      >
                        Nasıl Yapılır Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>


          <Modal isOpen={getIsEditAddProduct} toggle={toggleAddProduct}>
            <ModalHeader toggle={toggleAddProduct} tag="h4">
              {!!getIsEditAddProduct ? "Ürün Düzenle" : "Ürün Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                 
                  return false;
                }}
              >
                    <MultiSelectOption handleChange={handleChangeProductList} fullData={getProductList} value={getSelectProduct} type={"product"}  />

                    <MultiSelectOption handleChange={handleChangeGramList} fullData={getGramList} value={getSelectGramList} type={"gram"}  />


                  
                   { getSelectProduct.map((item, index) => (
                    <div key={index} className="mb-3">
                    <Label className="form-label">{index + 1 }. Birim</Label>
                    <Input
                      name="units"
                      type="text"
                      placeholder="Birim"
                      onChange={validation2.handleChange}
                      onBlur={validation2.handleBlur}
                      value={validation2.values.units || ""}
                      invalid={
                        validation2.touched.units && validation2.errors.units ? true : false
                      }
                    />
                    {validation2.touched.units && validation2.errors.units ? (
                      <FormFeedback type="invalid">{validation2.errors.units}</FormFeedback>
                    ) : null}
                  </div>   
                    
                    ))}
                                   

                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        onClick={()=> _addProducts()}
                        className="btn btn-success save-user"
                      >
                        Ürünleri Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal isOpen={getIsAddBlog} toggle={toggleAddBlog}>
            <ModalHeader toggle={toggleAddBlog} tag="h4">
              {!!getIsAddBlog ? "Blog Düzenle" : "Blog Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                
                  return false;
                }}
              >
                    <MultiSelectOption handleChange={handleChangeBlog} fullData={getBlogList} value={getSelectBlog} type={"blog"}  />
                                   
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                      onClick={()=> postBlogInRecipes()}
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Bloglara Ekle
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Tarifi Düzenle" : "Tarif Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                
                <div className="mb-3">
                      <Label className="form-label">Tarif Adı</Label>
                      <Input
                        name="recipesName"
                        type="text"
                        placeholder="Tarif Adı"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.recipesName || ""}
                        invalid={
                          validation.touched.recipesName && validation.errors.recipesName ? true : false
                        }
                      />
                      {validation.touched.recipesName && validation.errors.recipesName ? (
                        <FormFeedback type="invalid">{validation.errors.recipesName}</FormFeedback>
                      ) : null}
                    </div>
                  
                    <div className="mb-3">
                      <Label className="form-label">Açıklama</Label>
                      <Input
                        name="longDesc"
                        type="text"
                        placeholder="Tarif Açıklama"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.longDesc || ""}
                        invalid={
                          validation.touched.longDesc && validation.errors.longDesc ? true : false
                        }
                      />
                      {validation.touched.longDesc && validation.errors.longDesc ? (
                        <FormFeedback type="invalid">{validation.errors.longDesc}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Kısa Açıklama</Label>
                      <Input
                        name="shortDesc"
                        type="text"
                        placeholder="Tarif Kısa Açıklama"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.shortDesc || ""}
                        invalid={
                          validation.touched.shortDesc && validation.errors.shortDesc ? true : false
                        }
                      />
                      {validation.touched.shortDesc && validation.errors.shortDesc ? (
                        <FormFeedback type="invalid">{validation.errors.shortDesc}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="d-flex">

                    <div className="mb-3">
                      <Label className="form-label">Pişirme Süresi</Label>
                      <Input
                        name="cookingTime"
                        type="number"
                        placeholder="Pişirme Süresi"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.cookingTime || ""}
                        invalid={
                          validation.touched.cookingTime && validation.errors.cookingTime ? true : false
                        }
                      />
                      {validation.touched.cookingTime && validation.errors.cookingTime ? (
                        <FormFeedback type="invalid">{validation.errors.cookingTime}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Hazırlama Süresi</Label>
                      <Input
                        name="prepationTime"
                        type="number"
                        placeholder="Hazırlama Süresi"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.prepationTime || ""}
                        invalid={
                          validation.touched.prepationTime && validation.errors.prepationTime ? true : false
                        }
                      />
                      {validation.touched.prepationTime && validation.errors.prepationTime ? (
                        <FormFeedback type="invalid">{validation.errors.prepationTime}</FormFeedback>
                      ) : null}
                    </div>
                  
                  </div>

                  <Row>
                  <div className="d-flex">
                  <Label className="form-label">Hikayede Görüntülensin Mi?</Label>

                          <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActiveStory}
                              onChange={() =>
                                setIsActiveStory(!getIsActiveStory)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                        
                       
                        </div>
                        </Row>

                
                  
               

            
 

                <Row xs={12}>
                <Col xs={12} className="mb-3">
                            <label htmlFor="resume">Görseli(800*600)</label>
                         <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatusLogo}
          maxFiles={1}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          canRemove={false}
          PreviewComponent={Preview}
          accept="image/*"
        />
                
                          </Col>
                </Row>
         

              <div className="d-flex">
                <Row>
                <label htmlFor="resume">Tarif Görseli</label>

                <Col xs={12} className="mb-3">
                            <img
                  className=" avatar-xl"
                  src={'https://buharasubdomain.stechomeyazilim.info/' +  getFileNameLogo}
                  alt=""
                />
                          </Col>
                </Row>
  
                <Row>
                <Col xs={12} className="mb-3">
                <label htmlFor="resume">Web Tarif Görseli</label>
                         <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatusLogo}
          maxFiles={1}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          canRemove={false}
          PreviewComponent={Preview}
          accept="image/*"
        />
                
                          </Col>
                </Row>
                </div>
                
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;