import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
  addProducts as onAddProducts,
  updateProducts as onUpdateProducts,
  getProducts as onGetProducts,

  deleteProjects as onDeleteProjects,
} from "store/contacts/actions";

import axios from 'axios';


import {
  Name,
  Status
}
  from "./EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";
import {Base64} from 'js-base64';
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';
import MultiSelectOption from "../MultiSelectOption";
import "jspdf-autotable";
import * as XLSX from 'xlsx';

function EcommerceOrder() {

  //meta title
  document.title = "Ürünler | BUHARA - Admin Paneli";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [getEditProject, setEditProject] = useState(null);
  const [getIsActive, setIsActive] = useState(false);
  const [getIsActiveOwner, setIsActiveOwner] = useState(false);


  const [getSelectBlog, setSelectBlog] = useState([]);
  const [getIsAddBlog, setIsAddBlog] = useState(false);
  const [getBlogList, setBlogList] = useState([]);
  const [getOrderBlog, setOrderBlog] = useState(null);

  const [getIsAddLink, setIsAddLink] = useState(false);
  const [getCountStep, setCountStep] = useState(1);

  const [getSelectLink, setSelectLink] = useState([]);
  const [getLinkList, setLinkList] = useState([]);
  const [getProductsLinkList, setProductsLinkList] = useState([]);

  const [getWsText, setWsText] = useState(null);
  const [getSelectReply, setSelectReply] = useState([]);

  
  const [getIsAddQuesiton, setIsAddQuestion] = useState(false);
  const [getQuestion, setQuestion] = useState([]);
  const [loading, setLoading] = useState(false);

  
  const [getFileTr, setFileNameTR] = useState(null);
  const [getFileEng, setFileNameEng] = useState(null);

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://buharaadmin.stechomeyazilim.info:8099/uploadfile', body }
  }
   
  const handleChangeStatusTR = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileNameTR(result.file.filename)
        }
      }
    }
  }
  const handleChangeStatusEng = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileNameEng(result.file.filename)
        }
      }
    }
  }

  const _getBlogList = async () => {
    setBlogList([])
    await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getBlog/select/`)
    .then((res) => {
      res.data.map((item, index) => (

        setBlogList(oldArray => [...oldArray,{label:item.Blog_Title,value:item.ID}] )

      ))

    })
  }


  const postBlogInProducts = async() => {
    try{
    
      if(getSelectBlog.length > 0){

        getSelectBlog.map(async(item, index) => {
          let data = {
            Products_ID:getOrderBlog.ID,
            Blog_ID:item.value
          }    
              
          await axios.post(`https://buharaapi.stechomeyazilim.info:5210/postBlogInProducts/insert`, data).then(async(res) => {
    
          dispatch(onGetProducts());
    
          toggleAddBlog()
          })
       })
      
      }else{
        showToast(false)
      }
     /* axios.delete(`https://buharaapi.stechomeyazilim.info:5210/deleteRecipes/${ID}`).then(async(res) => {
    
      console.log("delete1",res)
    }) */
    
    }catch (err) {
        console.log("postBlogInRecipespostBlogInRecipes",err)
      }
    
      }

      
 const toggleAddQuestion = () => {
        if (getIsAddQuesiton) {
          setIsAddQuestion(false);
    
        } else {
          setIsAddQuestion(true);
        }
      }

 const toggleAddLink = () => {
        if (getIsAddLink) {
          setIsAddLink(false);
    
        } else {
          setIsAddLink(true);
        }
      }

      
      const toggleAddQuesiton = () => {
        if (getIsAddBlog) {
          setIsAddQuestion(false);
    
        } else {
          setIsAddQuestion(true);
        }
      }

  const toggleAddBlog = () => {
    if (getIsAddBlog) {
      setIsAddBlog(false);

    } else {
      setIsAddBlog(true);
    }
  }


  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      productName: (getEditProject && getEditProject.Products_Name) || '',
     productDesc:(getEditProject && getEditProject.Products_Desc) || '',
      productShortDesc: (getEditProject && getEditProject.Products_ShortDescMobile) || '',
      bottomBannerText: (getEditProject && getEditProject.Bottom_BannerText) || '',
      productEngName: (getEditProject && getEditProject.Products_EngName) || '',
      productContent: (getEditProject && getEditProject.Products_ContentMobile) || '',
      productClue:(getEditProject && getEditProject.Products_ClueMobile) || '',
      productBarcode:(getEditProject && getEditProject.Products_Barcode) || '',
      productGR: (getEditProject && getEditProject.Products_GR) || ''
    },
    validationSchema: Yup.object({
      productName: Yup.string().required("Ürün Başlığını Giriniz"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          ID: order ? order.ID : 0,
          Products_Name: values.productName,
          Products_Desc:values.productDesc,
          Products_Owner:getIsActiveOwner,
           Products_Image:getFileTr,
          Products_Desc:values.productDesc,
         // Products_Trend:
          Products_ShortDescMobile:values.productShortDesc,
          Products_ContentMobile:values.productContent,
          Products_ClueMobile:values.productClue,
          Products_Barcode:values.productBarcode,
          Products_IsActive:getIsActive,
          Products_EngName:values.productEngName,
          Products_ImageEng:getFileEng,
          Products_GR: values.productGR
        }
        // update order
        dispatch(onUpdateProducts(updateOrder));
        dispatch(onGetProducts());
        validation.resetForm();
      } else {
        const newOrder = {
          Products_Name: values["productName"],
          Products_Desc:values["productDesc"],
         Products_Owner:getIsActiveOwner,
          Products_Image:getFileTr,
          Products_Desc:values["productDesc"],
         // Products_Trend:
          Products_ShortDescMobile:values["productShortDesc"],
          Products_ContentMobile:values["productContent"],
          Products_ClueMobile:values["productClue"],
          Products_Barcode:values["productBarcode"],
          Products_IsActive:getIsActive,
          Products_EngName:values["productEngName"],
         Products_ImageEng:getFileEng,
         Products_GR: values["productGR"]

        };
        // save new order
        dispatch(onAddProducts(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { orders } = useSelector(state => ({
    orders: state.contacts.products,
  }));

  useEffect(() => {
   if (orders && !orders.length) {
      dispatch(onGetProducts());
      setIsEdit(false);
  }
  }, [dispatch, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {
    const order = arg;
    setEditProject(order)
    setOrder(order);
    
    setFileNameTR(order.Products_Image)
    setFileNameEng(order.Products_ImageEng)

    setIsActive(order.Products_IsActive)
    setIsActiveOwner(order.Products_Owner)

    setIsEdit(true);

    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.ID) {
      dispatch(onDeleteProjects(order.ID));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  
  const _getLinkList = async (ID) => {   
    await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getProductsLink/select/${ID}`)
    .then((res) => {
      setProductsLinkList(res.data)
    })
  }



  const handleAddLink = arg => {
    const order = arg;
    setLinkList(order)
    setIsAddLink(true)
    setSelectLink([])


    _getLinkList(order.ID)
    if(order.Products_Link.length>0){

      order.Products_Link.map(async(item, index) => {
        setSelectLink(oldArray => [...oldArray,{label:item.Products_Link_Header,value:item.ID}] )
     })
    }

    toggleAddLink();
  }

  
  const handleAddQuestion = arg => {
    const order = arg;

    setQuestion(order.Products_Question);
    setIsAddQuestion(true);

    
    toggleAddQuesiton();
  }


  const _deleteProductsBlog = async(ID) => {
    try{
    
    
       axios.delete(`https://buharaapi.stechomeyazilim.info:5210/deleteProductsBlog/${ID}`).then(async(res) => {
    
      
    
          dispatch(onGetProducts());

 
       setIsAddMaking(false);
       setModal(false);
       setMakinModal(false)
          })
      

    
    }catch (err) {
        console.log("_deleteProductsBlog",err)
      }
    
      }

  const handleAddBlog = arg => {
    const order = arg;
    setOrderBlog(order);
    setIsAddBlog(true);
    setSelectBlog([])

    _getBlogList()

    
    if(order.Products_Blog.length>0){

      order.Products_Blog.map(async(item, index) => {
        setSelectBlog(oldArray => [...oldArray,{label:item.Blog.Blog_Title,value:item.Blog.ID}] )
     })
    }

    toggleAddBlog();
  }

  const handleChangeBlog= async (val,type) => {
    setSelectBlog(val)
  }



  const handleDelete = async(item) => {
    try{
        
        
      console.log("şmsdşlf",item)
      axios.delete(`https://buharaapi.stechomeyazilim.info:5210/deleteProductsBlog/${item.ID}`).then(async(res) => {
   
     
   
         dispatch(onGetProducts());


    

         })
     

   
   }catch (err) {
       console.log("handleDelete",err)
     }
};

  const columns = useMemo(
    () => [
      {
        Header: "Ürün Görseli",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.Products_Image ? (
              <div className="avatar-xl">
             { cellProps.Products_Name != null ?  <span className="avatar-title rounded-circle">
                  {cellProps.Products_Name.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="avatar-xl"
                  src={'https://buharasubdomain.stechomeyazilim.info/' + cellProps.Products_Image}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
   
      {
        Header: "Ürün Adı",
        accessor: "Products_Name",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Mobil Kısa Açıklama",
        accessor: "Products_ShortDescMobile",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },

      {
        Header: "Ürün Barkod",
        accessor: "Products_Barcode",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },

      {
        Header: 'Ürün Blogları',
        accessor: 'Products_Blog',
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {cellProps.Products_Blog != null && cellProps.Products_Blog.length > 0 ?
            cellProps.Products_Blog.map((item, index) => {
              return(
               item.Blog.Blog_Title != null ? 
               <Link               
               to="#"
               className="text-success"
               onClick={() => {
                 handleDelete(item);
               }}
               key={index}
                >
               
                <a>
                  {item.Blog.Blog_Title}
                </a> 


                <button
                        type="submit"
                        onClick={()=> _deleteProductsBlog(item.ID)}
                        className="btn btn-danger save-user"
                      >
                        Sil
                      </button>

                </Link>: null
             )}
            ) :  <div>
               
            <a>
             İçerik Bulunmamaktadır!
            </a> 

            </div>}
          </>
        ),
      },
    
      {
        Header: 'Aktiflik Durumu',
        accessor: 'Products_IsActive',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },

      {
        Header: 'Ürün',
        accessor: 'Products_GR',
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
          
              
               <div               
               to="#"
               className="text-success"
             
            
                >
               
                <a>
                  {cellProps.Products_GR}   {cellProps.Products_UnitText}
                </a> 

                </div>
           
           
            
          </>
        ),
      },
    

      {
        Header: 'Ürün Sahipliği',
        accessor: 'Products_Owner',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },


      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
                <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleAddLink(orderData);
                }}
              >
                Link Ekle
               
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleAddQuestion(orderData);
                }}
              >
                Ürün Sorunları
               
              </Link>

                  <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleAddBlog(orderData);
                }}
              >
                 Blog Ekle
               
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }



  const setChoiceLink = async(arg) => {
    
   console.log("kslndflk",arg.target.value)
      setSelectLink(arg.target.value);
   
   }


   const arrayBufferToBinaryString = (buffer) => {
    const binaryArray = new Uint8Array(buffer);
    const binaryString = binaryArray.reduce((data, byte) => {
      return data + String.fromCharCode(byte);
    }, '');
    return binaryString;
  };



  function showToast(type,type2) {

    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = type2 + " Başarıyla Veri Giriş İşleminiz Gerçekleşmiştir!"
    } else{
       ele = "error"
       message = type2 +  " Aynı Verileri Eklediniz!"
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  
  const handleFileUpload = async(e) => {
    try{
    setLoading(true)
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryString = arrayBufferToBinaryString(event.target.result);
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      
      // Assuming the first row contains headers
      const headers = data[0];

      console.log("lkn1sdlkf",data)

    
     

      const formattedData = data.slice(1).map((row) => {
        const rowData = {};
        row.forEach((cell, index) => {
          rowData[headers[index]] = cell;
        });
        return rowData;
      });



      const existingCodes = new Set(orders.map((user) => user.Products_Name));


      for (const row of formattedData) {

        console.log("sdfmlsdf",row)

        if (existingCodes.has(row.TÜRKÇE)) {
          showToast(false,row.TÜRKÇE)

          continue; // Aynı Products2_Code'ye sahip olanları atla
        }

       // var localDate = moment().local();

           
        const newOrder = {
          Products_Name: row.TÜRKÇE,
          Products_Owner: true,
          Products_Desc:row.hazırlamasüresi,
          Products_ShortDescMobile:row.ürünkısaaçıklama,
          Products_ContentMobile:row.ürüniçeriği,
          Products_ClueMobile:row.kullanımipuçları,
          Products_Barcode:row.BARKOD,
          Products_IsActive:true,
        //  anaID

        Products_UnitText : "GR",
          Products_GR : parseInt(row.GRAMAJ),
          Products_SubCategory_ID : parseInt(row.altID),
          Products_Carbohydrate:row.KARBONHİDRAT,
          	Product_Protein:row.PROTEİN,
            	Products_Energy:row.Enerji,
              	Product_Oil: row.Yağ,
                Products_EngName:row.İNGİLİZCE
        };
        // save new order
     
        showToast(true,row.TÜRKÇE)

        dispatch(onAddProducts(newOrder));

          
      }
   
      dispatch(onGetProducts())
      setTimeout(() => setLoading(false), 1000)

      }

    reader.readAsArrayBuffer(file);

}catch (err) {
  console.log("postBlogInRecipespostBlogInRecipes",err)
}
  }
  
  const setChoiceQuestionText = async(arg) => {
    
    await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getProductsQuestionReply/select/${arg.target.value}`)
    .then((res) => {
      console.log("knsldkf",res.data)
      setSelectReply(res.data);
    })
   }

   const handleClick = () => {
    document.getElementById('fileInput').click();
  };

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Yönetim" breadcrumbItem="Ürünler" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                {loading && <div className="loading-screen">Yükleniyor...</div>}

                <button  className="btn-rounded  mb-2 me-2" onClick={handleClick}>Excel Dosyasını Yükle</button>
      <input
        id="fileInput"
        type="file"
        accept=".xlsx, .xls"
        style={{ display: 'none' }}
        onChange={handleFileUpload}
      />

                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    isAddProducts={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={getIsAddLink} toggle={toggleAddLink}>
            <ModalHeader toggle={toggleAddLink} tag="h4">
              {!!getIsAddLink ? "Link Düzenle" : "Link Ekle"}
            </ModalHeader>
            <ModalBody>
             

            <div className="mb-3">
                      <Label className="form-label">Link Başlığı Seçiniz</Label>
                      <Input
                        name="questionText"
                        type="select"
                        className="form-select"
                        onChange={(text) => setChoiceLink(text)}
                        value={
                          getWsText
                        }>
                        {getProductsLinkList.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Products_Link_Header}</option>
                        ))}
                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>

                            
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                      onClick={()=> postBlogInProducts()}
                        type="submit"
                        className="btn btn-success save-user"
                      >
                       Cevabı Gönder
                      </button>
                    </div>
                  </Col>
                </Row>
             
            </ModalBody>
          </Modal>


          <Modal isOpen={getIsAddQuesiton} toggle={toggleAddQuestion}>
            <ModalHeader toggle={toggleAddQuestion} tag="h4">
              {!!getIsAddQuesiton ? "Soru Düzenle" : "Soru Ekle"}
            </ModalHeader>
            <ModalBody>
             
            <div className="mb-3">
                      <Label className="form-label">Soruyu Seçiniz</Label>
                      <Input
                        name="questionText"
                        type="select"
                        className="form-select"
                        onChange={(text) => setChoiceQuestionText(text)}
                        value={
                          getWsText
                        }>
                         <option value="">Lütfen Seçim Yapınız</option>

                        {getQuestion.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Products_Question_Text}</option>
                        ))}
                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>

                    
                    <div className="mb-3">
                      <Label className="form-label">Link</Label>
                      <Input
                        name="questionReply"
                        type="text"
                        placeholder="Cevabınız"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={getSelectLink.Products_Link_Address || ""}
                        invalid={
                          validation.touched.questionReply && validation.errors.questionReply ? true : false
                        }
                      />
                      {validation.touched.questionReply && validation.errors.questionReply ? (
                        <FormFeedback type="invalid">{validation.errors.questionReply}</FormFeedback>
                      ) : null}
                    </div>
                    

                    <div className="mb-3">
                      <Label className="form-label">Cevabınız</Label>
                      <Input
                        name="questionReply"
                        type="text"
                        placeholder="Cevabınız"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.questionReply || ""}
                        invalid={
                          validation.touched.questionReply && validation.errors.questionReply ? true : false
                        }
                      />
                      {validation.touched.questionReply && validation.errors.questionReply ? (
                        <FormFeedback type="invalid">{validation.errors.questionReply}</FormFeedback>
                      ) : null}
                    </div>
                    
                    
                    {getSelectReply.map(async(item, index) => {<a>{item.Products_Question_Reply_Text}</a>})}


                            
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                      onClick={()=> postBlogInProducts()}
                        type="submit"
                        className="btn btn-success save-user"
                      >
                       Cevabı Gönder
                      </button>
                    </div>
                  </Col>
                </Row>
             
            </ModalBody>
          </Modal>


          <Modal isOpen={getIsAddBlog} toggle={toggleAddBlog}>
            <ModalHeader toggle={toggleAddBlog} tag="h4">
              {!!getIsAddBlog ? "Blog Düzenle" : "Blog Ekle"}
            </ModalHeader>
            <ModalBody>
              
                    <MultiSelectOption handleChange={handleChangeBlog} fullData={getBlogList} value={getSelectBlog} type={"blog"}  />
                                   
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                      onClick={()=> postBlogInProducts()}
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Bloglara Ekle
                      </button>
                    </div>
                  </Col>
                </Row>
            
            </ModalBody>
          </Modal>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Ürün Düzenle" : "Ürün Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    
                  <div className="d-flex">
                    <div className="mb-3">
                      <Label className="form-label">TR Ürün Adı</Label>
                      <Input
                        name="productName"
                        type="text"
                        placeholder="Ürün Adı"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.productName || ""}
                        invalid={
                          validation.touched.productName && validation.errors.productName ? true : false
                        }
                      />
                      {validation.touched.productName && validation.errors.productName ? (
                        <FormFeedback type="invalid">{validation.errors.productName}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">İngilizce Başlık</Label>
                      <Input
                        name="productEngName"
                        type="text"
                        placeholder="İngilizce Başlık"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.productEngName || ""}
                        invalid={
                          validation.touched.productEngName && validation.errors.productEngName ? true : false
                        }
                      />
                      {validation.touched.productEngName && validation.errors.productEngName ? (
                        <FormFeedback type="invalid">{validation.errors.productEngName}</FormFeedback>
                      ) : null}
                    </div>
                   </div>

                   <div className="mb-3">
                      <Label className="form-label">Açıklama</Label>
                      <Input
                        name="productDesc"
                        type="text"
                        placeholder="Açıklama"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.productDesc || ""}
                        invalid={
                          validation.touched.productDesc && validation.errors.productDesc ? true : false
                        }
                      />
                      {validation.touched.productDesc && validation.errors.productDesc ? (
                        <FormFeedback type="invalid">{validation.errors.productDesc}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Kısa Açıklama</Label>
                      <Input
                        name="productShortDesc"
                        type="text"
                        placeholder="Kısa Açıklama"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.productShortDesc || ""}
                        invalid={
                          validation.touched.productShortDesc && validation.errors.productShortDesc ? true : false
                        }
                      />
                      {validation.touched.productShortDesc && validation.errors.productShortDesc ? (
                        <FormFeedback type="invalid">{validation.errors.productShortDesc}</FormFeedback>
                      ) : null}
                    </div>
                    

                 
                    <div className="mb-3">
                      <Label className="form-label">Barkod</Label>
                      <Input
                        name="productBarcode"
                        type="text"
                        placeholder="Barkod"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.productBarcode || ""}
                        invalid={
                          validation.touched.productBarcode && validation.errors.productBarcode ? true : false
                        }
                      />
                      {validation.touched.productBarcode && validation.errors.productBarcode ? (
                        <FormFeedback type="invalid">{validation.errors.productBarcode}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Ürün İpucu</Label>
                      <Input
                        name="productClue"
                        type="text"
                        placeholder="İpucu"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.productClue || ""}
                        invalid={
                          validation.touched.productClue && validation.errors.productClue ? true : false
                        }
                      />
                      {validation.touched.productClue && validation.errors.productClue ? (
                        <FormFeedback type="invalid">{validation.errors.productClue}</FormFeedback>
                      ) : null}
                    </div>


                    <div className="mb-3">
                      <Label className="form-label">Ürün GRAMAJ</Label>
                      <Input
                        name="productGR"
                        type="number"
                        placeholder="GRAMAJ"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.productGR || ""}
                        invalid={
                          validation.touched.productGR && validation.errors.productGR ? true : false
                        }
                      />
                      {validation.touched.productGR && validation.errors.productGR ? (
                        <FormFeedback type="invalid">{validation.errors.productGR}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Ürün İçerik</Label>
                      <Input
                        name="productContent"
                        type="text"
                        placeholder="İçerik"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.productContent || ""}
                        invalid={
                          validation.touched.productContent && validation.errors.productContent ? true : false
                        }
                      />
                      {validation.touched.productContent && validation.errors.productContent ? (
                        <FormFeedback type="invalid">{validation.errors.productContent}</FormFeedback>
                      ) : null}
                    </div>
                 

                  <Row>
                <Col xs={12} className="mb-3">
                            <label htmlFor="resume">TR Ürün Görseli(800*600)</label>
                         <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatusTR}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          canRemove={false}
          PreviewComponent={Preview}
          accept="image/*"
        />
                
                          </Col>
                </Row>
                
               { getFileTr != null ? <Row>
                <label htmlFor="resume">TR Eklenen Resim</label>

                <Col xs={12} className="mb-3">
                            <img
                  className=" avatar-xl"
                  src={'https://buharasubdomain.stechomeyazilim.info/' +  getFileTr}
                  alt=""
                />
                          </Col>
                </Row> : null}

                <Row>
                <Col xs={12} className="mb-3">
                            <label htmlFor="resume">ENG Görseli(800*600)</label>
                         <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatusEng}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          canRemove={false}
          PreviewComponent={Preview}
          accept="image/*"
        />
                
                          </Col>
                </Row>

                { getFileEng != null ?   <Row>
                <label htmlFor="resume">ENG Eklenen Resim</label>

                <Col xs={12} className="mb-3">
                            <img
                  className=" avatar-xl"
                  src={'https://buharasubdomain.stechomeyazilim.info/' +  getFileEng}
                  alt=""
                />
                          </Col>
                </Row> :  null }

          
                  <div className="d-flex">
                  <label htmlFor="resume">Aktiflik Durumu</label>

                          <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switch"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                        
                       
                        </div>
                        

                        <div className="d-flex">
                        <label htmlFor="resume">Ürün Buhara'ya Ait?</label>

                          <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActiveOwner}
                              onChange={() =>
                                setIsActiveOwner(!getIsActiveOwner)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                        
                       
                        </div>
                
               
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;