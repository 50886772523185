import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import {  Email  } from "./contactlistCol";
import {ExportToExcel} from './ExportToExcel'
import { Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import jsPDF from "jspdf";
import "jspdf-autotable";
import Dropzone from 'react-dropzone-uploader';


import {
  getRecipesCategory as onGetRecipesCategory,
  addRecipesCategory as onAddRecipesCategory,
  updateRecipesCategory as onUpdateRecipesCategory,
  deleteRecipesCategory as onDeleteRecipesCategory,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Tarif Kategorileri | BUHARA ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getFileName, setFileName] = useState(null);

  
  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }
  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      categoryName: (contactEdit && contactEdit.Recipes_Category_Text) || "",
    },
    validationSchema: Yup.object({
      categoryName: Yup.string(3).required("Tarifin Adını Giriniz"),
    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Recipes_Category_Text:values.categoryName,
          Recipes_Category_Image:  getFileName
        };
        dispatch(onUpdateRecipesCategory(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Recipes_Category_Text:values["categoryName"],
          Recipes_Category_Image:getFileName
        };
        // save new user
        dispatch(onAddRecipesCategory(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.recipesCategory,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);  
    
  

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "ID",
        accessor: "ID",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.Recipes_Category_Image ? (
              <div className="avatar-xs">
              {cellProps.Recipes_Category_Text != null ?  <span className="avatar-title rounded-circle">
                  {cellProps.Recipes_Category_Text.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="avatar-xl"
                  src={'https://buharasubdomain.stechomeyazilim.info/' + cellProps.Recipes_Category_Image}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Kategori Adı",
        accessor: "Recipes_Category_Text",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );


  useEffect(() => {
   
    if (users && !users.length) {
      dispatch(onGetRecipesCategory());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
   // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = (arg) => {
    const user = arg;

    console.log("user12321",user)
    setEditContact(user);

    setIsEdit(true);

    setFileName(user.Recipes_Category_Image)

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteRecipesCategory(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  }

   const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }


  const keyField = "id";
  const fileName = "myfile"; // here enter filename for your excel file

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Tarif Kategori Listesi";
    const headers = [["Adı", "Telefon Numarasi", "İlçe", "Adres", "Mail Adresi"]];

    const data = users.map(elt=> [elt.NameSurname, elt.GsmNumber, elt.District, elt.Adress, elt.MailAdress]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")
  }

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://buharaadmin.stechomeyazilim.info:8099/uploadfile', body }
  }
  

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Tarif Kategorileri" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
      
                <ExportToExcel apiData={users} fileName={fileName} />

                <Button
    type="button"
    color="danger"
    className="btn  mb-2 me-2"
    onClick={exportPDF}>
       <i className="mdi mdi-export me-1" />
                     PDF
                </Button>
                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isADDProductCategory={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Tarif Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                       
                        
                      
                            <div className="mb-3">
                              <Label className="form-label">Kategori Adı</Label>
                              <Input
                                name="categoryName"
                                label="Kategori Adı"
                                type="text"
                                placeholder="Kategori Adını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.categoryName || ""}
                                invalid={
                                  validation.touched.categoryName &&
                                    validation.errors.categoryName
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.categoryName &&
                                validation.errors.categoryName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.categoryName}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                    <label htmlFor="resume">Kategori Fotoğrafı (800*600)</label>
                         <Dropzone
                         maxFiles={1}
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          canRemove={true}
          PreviewComponent={Preview}
          accept="image/*"
        />
                    </div>


                    <Row>
                <label htmlFor="resume"> Mevcut Eklenen Resim</label>

                <Col xs={12} className="mb-3">
                            <img
                  className=" avatar-xl"
                  src={'https://buharasubdomain.stechomeyazilim.info/' +  getFileName}
                  alt=""
                />
                          </Col>
                </Row>

                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
