import axios from "axios";
import { del, get, post, put,patch } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);

// get Products

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });

// get Events

// add Events

// update Event


// delete Event
export const deleteEvent = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteEventMega/${project}`);


// get Categories

// get chats

// get groups
export const getGroups = () => get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS);

// get messages


// post messages
export const getselectedmails = (selectedmails) => post(url.GET_SELECTED_MAILS, selectedmails);

//post setfolderonmails
export const setfolderonmails = (selectedmails, folderId, activeTab) => post(url.SET_FOLDER_SELECTED_MAILS, { selectedmails, folderId, activeTab });

// get orders
export const getOrders = () => get(url.GET_ORDERS);

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order);

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order);

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } });

// get cart data
export const getCartData = () => get(url.GET_CART_DATA);

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer);

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer);

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } });

// get shops
export const getShops = () => get(url.GET_SHOPS);

// get wallet
export const getWallet = () => get(url.GET_WALLET);

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS);

// get crypto product
export const getCryptoProduct = () => get(url.GET_CRYPTO_PRODUCTS);

// get invoices
export const getInvoices = () => get(url.GET_INVOICES);

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } });

// get jobs
export const getJobList = () => get(url.GET_JOB_LIST);

// get Apply Jobs
export const getApplyJob = () => get(url.GET_APPLY_JOB);

// get project
export const getProjects = () => get(url.GET_PROJECTS);

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });

// get tasks
export const getTasks = () => get(url.GET_TASKS);


export const deleteProductCategory = project =>
  del(`https://buharaapi.stechomeyazilim.info:5210/deleteProductCategory/${project}`);


export const getUsers = () => get(`https://buharaapi.stechomeyazilim.info:5210/getUsersList/select`);
export const updateUser = user => patch(`https://buharaapi.stechomeyazilim.info:5210/patchUser/${user.ID}`, user);
export const addNewUser = user => post(`https://buharaapi.stechomeyazilim.info:5210/postUser/insert`, user);
export const deleteUser = user => del(`https://buharaapi.stechomeyazilim.info:5210/deleteUsers/${user}`);


export const updateProductCategory = user => patch(`https://buharaapi.stechomeyazilim.info:5210/patchProductCategory/${user.ID}`, user);
export const updateMainSlider = user => patch(`https://buharaapi.stechomeyazilim.info:5210/patchMainSlider/${user.ID}`, user);
export const addMainSlider = user => post(`https://buharaapi.stechomeyazilim.info:5210/addMainSlider/insert`, user);
export const addProductCategory = user => post(`https://buharaapi.stechomeyazilim.info:5210/addProductCategory/insert`, user);

export const addRecipesCategory = user => post(`https://buharaapi.stechomeyazilim.info:5210/addRecipesCategory/insert`, user);
export const updateRecipesCategory = user => patch(`https://buharaapi.stechomeyazilim.info:5210/updateRecipesCategory/${user.ID}`, user);
export const getRecipesCategory = () => get(`https://buharaapi.stechomeyazilim.info:5210/getRecipesCategory/select`);

export const getProducts = () => get(`https://buharaapi.stechomeyazilim.info:5210/getProducts/select`);
export const updateProducts = user => patch(`https://buharaapi.stechomeyazilim.info:5210/updateProducts/${user.ID}`, user);
export const addProducts = user => post(`https://buharaapi.stechomeyazilim.info:5210/addProducts/insert`, user);


export const updateProject = user => patch(`https://buharaapi.stechomeyazilim.info:5210/updateProducts/${user.ID}`, user);

export const addRecipes = user => post(`https://buharaapi.stechomeyazilim.info:5210/addRecipes/insert`, user);

export const addCertifica = user => post(`https://buharaapi.stechomeyazilim.info:5210/addCertifica/insert`, user);

export const updateNews = user => patch(`https://buharaapi.stechomeyazilim.info:5210/patchBlog/${user.ID}`, user);

export const updateRecipes = user => patch(`https://buharaapi.stechomeyazilim.info:5210/patchRecipes/${user.ID}`, user);

export const updateRecipesReview = user => patch(`https://buharaapi.stechomeyazilim.info:5210/patchRecipesReview/${user.ID}`, user);

export const getPopup = () => get(`https://getjob.stechomeyazilim.info:5101/getPopupMega/select/`);

export const getWorkShop = () => get(`https://getjob.stechomeyazilim.info:5101/getSUBDOMAINMega/select/`);

export const getCourseSchedulesMega = () => get(`https://getjob.stechomeyazilim.info:5101/getCourseSchedulesMega/select/`);

export const getWorkshopSessionMega = () => get(`https://getjob.stechomeyazilim.info:5101/getWorkshopSessionMega/select/`);

export const getTeacherMega = () => get(`https://getjob.stechomeyazilim.info:5101/getTeacherMega/select/`);

export const getEventMega = () => get(`https://getjob.stechomeyazilim.info:5101/getEventMega/select/`);


export const getSlider = () => get(`https://buharaapi.stechomeyazilim.info:5210/getSlider/select/`)

export const getMainPageSlider = () => get(`https://buharaapi.stechomeyazilim.info:5210/getMainPageSlider/select/`)

export const getProjectsNew = () => get(`https://buharaapi.stechomeyazilim.info:5210/getProduct/select/`);

export const getNews = () => get(`https://buharaapi.stechomeyazilim.info:5210/getBlog/select/`);

export const getMegaMega = () => get(`https://getjob.stechomeyazilim.info:5101/getMegaMega/select/`);

export const getMailsLists= () => get(`https://getjob.stechomeyazilim.info:5101/getContactMessageMega/select/`);


export const getProductCategory= () => get(`https://buharaapi.stechomeyazilim.info:5210/getProductCategory/select/`);

export const getCertifica= () => get(`https://buharaapi.stechomeyazilim.info:5210/getCertifica/select/`);

export const addSlider = job => post(`https://buharaapi.stechomeyazilim.info:5210/addSlider/insert`, job);

export const getDynamicFormMega = () => get(`https://buharaapi.stechomeyazilim.info:5210/getProducts2/select/`);

export const updateDynamicForm = user => patch(`https://buharaapi.stechomeyazilim.info:5210/patchProducts2/${user.ID}`, user);

export const addDynamicForm = job => post(`https://buharaapi.stechomeyazilim.info:5210/postProducts2/insert`, job);


export const deleteDynamicForm = project =>
  del(`https://buharaapi.stechomeyazilim.info:5210/deleteProducts2/${project}`);


export const deleteSlider = project =>
  del(`https://buharaapi.stechomeyazilim.info:5210/deleteSlider/${project}`);
  
  export const addProject = job => post(`https://buharaapi.stechomeyazilim.info:5210/addProducts/insert`, job);

  export const updateSlider = user => patch(`https://buharaapi.stechomeyazilim.info:5210/patchSlider/${user.ID}`, user);

  const postFakeLogin = data => get(`https://buharaapi.stechomeyazilim.info:5210/getUsersLogin/select/${data.email}/${data.password}`, );

export const getEvents = () => get(`https://getjob.stechomeyazilim.info:5101/getEventMega/select/`);

export const getCategories = () => get(`https://getjob.stechomeyazilim.info:5101/getEventCategoryMega/select/`);

// update user

export const updatePopup = user => patch(`https://getjob.stechomeyazilim.info:5101/patchPopupMega/${user.ID}`, user);

export const updateSubDomain = user => patch(`https://getjob.stechomeyazilim.info:5101/patchSUBDOMAINMega/${user.ID}`, user);

export const updateCourseSchedules= user => patch(`https://getjob.stechomeyazilim.info:5101/patchCourseSchedulesMega/${user.ID}`, user);

export const updateWorkshopSession = user => patch(`https://getjob.stechomeyazilim.info:5101/patchWorkShopSessionMega/${user.ID}`, user);

export const updateTeacher = user => patch(`https://getjob.stechomeyazilim.info:5101/patchUserMega/${user.ID}`, user);



export const addPopup = job => post(`https://getjob.stechomeyazilim.info:5101/addPopup/send`, job);



export const deleteWorkshop2 = project =>
  del(`https://buharaapi.stechomeyazilim.info:5210/deleteProductsSubCategory/${project}`);


export const addNews = job => post(`https://buharaapi.stechomeyazilim.info:5210/addBlog/insert`, job);

export const addMega = job => post(`https://getjob.stechomeyazilim.info:5101/addMega/send`, job);

export const addCourseMega = job => post(`https://getjob.stechomeyazilim.info:5101/addCourseMega/send`, job);

export const updateSubdomain = user => patch(`https://getjob.stechomeyazilim.info:5101/patchSUBDOMAINMega/${user.ID}`, user);

export const updateContact = user => patch(`https://buharaapi.stechomeyazilim.info:5210/patchContact/${user.ID}`, user);

export const updateAuth = user => patch(`https://getjob.stechomeyazilim.info:5101/patchAuthMega/${user.ID}`, user);

export const updateMega = user => patch(`https://getjob.stechomeyazilim.info:5101/patchMegaMega/${user.ID}`, user);

export const updateGallery = user => patch(`https://getjob.stechomeyazilim.info:5101/patchGalleryMega/${user.ID}`, user);

export const updateEvent = event => patch(`https://getjob.stechomeyazilim.info:5101/patchEventMega/${event.ID}`, event);

export const addAppSetting = job => post(`https://getjob.stechomeyazilim.info:5101/postWSMega/send`, job);




// delete user
export const addWorkshop2 = job => post(`https://buharaapi.stechomeyazilim.info:5210/postProductsSubCategory/insert`, job);

export const getContact = () => get(`https://buharaapi.stechomeyazilim.info:5210/getContact/select/`);
export const getAuthority = () => get(`https://getjob.stechomeyazilim.info:5101/getUsersTypeMega/select/`);

export const getWorkshop2 = () => get(`https://buharaapi.stechomeyazilim.info:5210/getProductsSubCategory/select/`);

export const deleteGallery = project =>
  del(`https://buharaapi.stechomeyazilim.info:5210/deleteGallery/${project}`);

export const deleteMainSlider = project =>
  del(`https://buharaapi.stechomeyazilim.info:5210/deleteMainSlider/${project}`);

  export const deleteProjects = project =>
  del(`https://buharaapi.stechomeyazilim.info:5210/deleteProducts/${project}`);

  export const deleteRecipesCategory = project =>
  del(`https://buharaapi.stechomeyazilim.info:5210/deleteRecipesCategory/${project}`);

  export const deleteRecipes = project =>
    del(`https://buharaapi.stechomeyazilim.info:5210/deleteRecipes/${project}`);
  
  export const deleteCertifica = project =>
  del(`https://buharaapi.stechomeyazilim.info:5210/deleteCertifica/${project}`);

  export const updateCertifica = user => patch(`https://getjob.stechomeyazilim.info:5101/updateCertifica/${user.ID}`, user);


export const getGallery = () => get(`https://buharaapi.stechomeyazilim.info:5210/getMedia/select/`);

export const addNewEvent = event => post(`https://getjob.stechomeyazilim.info:5101/postEventMega/send/`, event);


export const getMessages = (roomId = "") => get(`https://getjob.stechomeyazilim.info:5101/getContactMessageDetailMega/select/${roomId}`);

export const addNewSubdomain = event => post(`https://getjob.stechomeyazilim.info:5101/postSubdomainMega/send/`, event);

export const getReferances = () => get(`https://buharaapi.stechomeyazilim.info:5210/getRecipes/select/`);

export const getRecipes = () => get(`https://buharaapi.stechomeyazilim.info:5210/getRecipes/select/`);

export const getChats = () => get(`https://buharaapi.stechomeyazilim.info:5210/getNotificationBuhara/select/`);


export const addNewJobList = job => post(url.ADD_NEW_JOB_LIST, job);

// update jobs
export const updateJobList = job => put(url.UPDATE_JOB_LIST, job);

// delete jobs
export const deleteJobList = job => del(url.DELETE_JOB_LIST, { headers: { job } });

// Delete Apply Jobs
export const deleteApplyJob = data => del(url.DELETE_APPLY_JOB, { headers: { data } });

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project);

export const addReferances = event => post(`https://getjob.stechomeyazilim.info:5101/postReferancesMega/send/`, event);

export const updateReferances = user => patch(`https://getjob.stechomeyazilim.info:5101/patchReferancesMega/${user.ID}`, user);

export const getSubProject = (ID = "") => get(`https://getjob.stechomeyazilim.info:5101/getProjectSubItem/select/${ID}`);

export const getTeacherWorkshop = () => get(`https://getjob.stechomeyazilim.info:5101/getTeacherWorkshopMega/select/`);

export const addLessonsTime = event => post(`https://getjob.stechomeyazilim.info:5101/postLessonsTimeMega/send/`, event);

export const updateLessonsTime = user => patch(`https://getjob.stechomeyazilim.info:5101/patchTeacherWorkshopMega/${user.ID}`, user);

export const getClassAttendance = () => get(`https://getjob.stechomeyazilim.info:5101/getClassAttendanceMega/select/`);

export const getClassesMega= () => get(`https://getjob.stechomeyazilim.info:5101/getClassesMega/select/`);


export const getDynamicFormExtraMega = (ID = "") => get(`https://getjob.stechomeyazilim.info:5101/getDynamicFormExtraMega/select/${ID}`);

export const gecRecipesReviewSelect = (ID = "") => get(`https://buharaapi.stechomeyazilim.info:5210/getRecipesReview/select/${ID}`);

export const updateWorkShop2 = user => patch(`https://buharaapi.stechomeyazilim.info:5210/patchProductsSubCategoryBuhara/${user.ID}`, user);


export const getDynamicFormExtraExtraMega = (ID = "") => get(`https://getjob.stechomeyazilim.info:5101/getDynamicFormExtraExtraMega/select/${ID}`);


export const addGallerry = job => post(`https://getjob.stechomeyazilim.info:5101/postGalleryMega/send`, job);

export const addMultipleGalleryForm = job => post(`https://getjob.stechomeyazilim.info:5101/postGalleryMultipleMega/send`, job);



export const deleteNewsFunc = project =>
  del(`https://buharaapi.stechomeyazilim.info:5210/deleteNews/${project}`);


export const deleteDynamicFormExtraExtra = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteDynamicExtraExtraFormMega/${project}`);


export const deleteDynamicFormExtra = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteDynamicFormExtraMega/${project}`);

export const deleteCourseSchedules = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteCourseSchedulesMega/${project}`);



export const deleteReferances = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteReferancesMega/${project}`);

export const deletePopup = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deletePopupMega/${project}`);




export const deleteMega = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteMega/${project}`);

export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } });

export const getUserProfile = () => get(url.GET_USER_PROFILE);


// get maillist


//update mail
export const updateMail = mail => put(url.UPDATE_MAIL, mail);

// get folderlist
export const selectFolders = () => get(url.SELECT_FOLDER);

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message);

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA);
export const getYearlyData = () => get(url.GET_YEARLY_DATA);
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA);

export const walletBalanceData = month =>
  get(`${url.GET_WALLET_DATA}/${month}`, { params: { month } });

export const getStatisticData = duration =>
  get(`${url.GET_STATISTICS_DATA}/${duration}`, { params: { duration } });

export const visitorData = duration =>
  get(`${url.GET_VISITOR_DATA}/${duration}`, { params: { duration } });

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } });

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS);

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  });
};
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  });
};

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  });
};

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  });
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
};
