import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';
import axios from "axios";

import LoadingModal from '../../Loading';


import {
  addGalleryMultiple as onAddMultipleGallery,

  addGallery as onAddGallery,
  updateGallery as onUpdateGalllery,
  getGallery as onGetGallery,
  deleteGallery as onDeleteGallery,

} from "store/contacts/actions";

import {
  Name,
  Status,
}
  from "./EcommerceOrderCol";
  import 'react-dropzone-uploader/dist/styles.css'
  import Dropzone from 'react-dropzone-uploader';

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";

function EcommerceOrder() {

  //meta title
  document.title = "Galeri | Mega - Admin Paneli";

  const [getIsActive, setIsActive] = useState(false);
  const  [getFileNameMainPhoto, setFileNameMainPhoto] = useState(null);
  
  const [loading, setLoading] = useState(true);
  const spinner = document.getElementById("spinner");
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    },5500);
  }

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }
   
  // handle the status of the file upload
  const handleChangeStatusBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileNameBanner(result.file.filename)

        }
      }
    }
  }

  const  handleChangeStatusMainPhoto = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileNameMainPhoto(result.file.filename)
        }
      }
    }
  }

    // handle the status of the file upload
    const handleChangeExtraPhoto = ({ xhr }) => {
      if (xhr) {
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            const result = JSON.parse(xhr.response);
            //setFileNameExtraPhoto(result.file.filename)
  
            setFileNameExtraPhoto(oldArray => [{fileName : result.file.filename},...oldArray] );

          }
        }
      }
    }
  
  const [getFileNameExtraPhoto, setFileNameExtraPhoto] = useState([]);

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [getEditSlider, setEditSlider] = useState(null);


  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (getEditSlider && getEditSlider.Gallery_Title) || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Başlığı Giriniz"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateSlider = {
          ID: order ? order.ID : 0,
          Gallery_Title: values.title,
          Gallery_Coverphoto: getFileNameMainPhoto,
          Gallery_IsActive: getIsActive
        };
        // update order
        dispatch(onUpdateGalllery(updateSlider));
        validation.resetForm();
      } else {
        const newOrder = {
          Gallery_Title:values["title"],
          Gallery_Coverphoto: getFileNameMainPhoto,
          Gallery_IsActive: getIsActive   
        };
        // save new order
        setLoading(true)
        dispatch(onAddGallery(newOrder));
       
        setTimeout(
          () =>  addMultiple(values["title"]), 
          3000
        )
       
      }
     
    },
  });

 const addMultiple= async(title) => {
  await axios.get(`https://getjob.stechomeyazilim.info:5101/searchGalleryMega/select/${title}`)
  .then((res) => {
    console.log("lksdfkl123",res.data)
    getFileNameExtraPhoto.map((item, i) => {
      const newGalleryMultiple = {
        Gallery_Multiple_Link:item.fileName,
        Gallery_ID: res.data[0].ID,
      }
    
      
      dispatch(onAddMultipleGallery(newGalleryMultiple));
    })
    validation.resetForm();
    toggle();
  })
  }

  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { orders } = useSelector(state => ({
    orders: state.contacts.gallery,
  }));

  useEffect(() => {
   if (orders && !orders.length) {
      dispatch(onGetGallery());
    }
  }, [dispatch, orders]);

  useEffect(() => {
    console.log("orders123",orders)
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {
    const order = arg;
    setEditSlider(order)
    setOrder(order);
    setIsEdit(true);
    setIsActive(order.Gallery_IsActive)
    setFileNameMainPhoto(order.Gallery_Coverphoto)
    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.ID) {
      dispatch(onDeleteGallery(order.ID));
      setDeleteModal(false);
    }
  };

  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'BAŞLIK',
        accessor: 'Media_Tile',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        Header: "Ana Görsel",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.Media_Url ? (
              <div className="avatar-xl">
               {cellProps.Media_Tile != null ? <span className="avatar-title rounded-circle">
                  {cellProps.Media_Tile.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className=" avatar-xl"
                  src={'https://buharabaharatadmin.stechomeyazilim.com' +  cellProps.Media_Url}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
  
     
      {
        Header: 'Aktiflik Durumu',
        accessor: 'Media_IsActive',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  return (
    !loading && (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Yönetim" breadcrumbItem="Galeri" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    isAddGallery={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Galeri Düzenle" : "Galeri Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >

<div className="mb-3">
                      <Label className="form-label">Başlık</Label>
                      <Input
                        name="title"
                        type="text"
                        placeholder="Başlık"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title || ""}
                        invalid={
                          validation.touched.title && validation.errors.title ? true : false
                        }
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                      ) : null}
                    </div>
                  
                 
                    <div className="mb-3">                  
                <Col xs={12} className="mb-3">
                            <label htmlFor="resume">Ana Görsel(800*600)</label>
                         <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatusMainPhoto}
          maxFiles={1}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          canRemove={false}
          PreviewComponent={Preview}
          accept="image/*"
        />
                
                          </Col>
                   </div>

 

                <div className="mb-3">                 
                <Col xs={12} className="mb-3">
                            <label htmlFor="resume">Diğer Görsel(6 Tane Daha Görsel Ekleyebilirsiniz)*</label>
                         <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeExtraPhoto}
          maxFiles={6}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          canRemove={false}
          PreviewComponent={Preview}
          accept="image/*"
        />
                
                          </Col>
                </div>
                
                <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>

                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
  
    </React.Fragment>
    )
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;