import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-rangeslider/lib/index.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";

import * as Yup from "yup";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  updateContact as onUpdateConcat,
  getContactMega as onGetContact,
} from "store/contacts/actions";

const EcommerceAddProduct = () => {
  document.title = "İletişim Bilgileri | Buhara Admin Paneli";

  const dispatch = useDispatch();

  const [getContactEdit, setContactEdit] = useState(null);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      youtubeadress: (getContactEdit && getContactEdit.Contact_Youtube) || '',
      instagramadress: (getContactEdit && getContactEdit.Contact_Instagram) || '',
      twitteradress: (getContactEdit && getContactEdit.Contact_Twitter) || '',
      fbadress: (getContactEdit && getContactEdit.Contact_Facebook) || '',
      email: (getContactEdit && getContactEdit.Contact_Email) || '',
      gsmNumber: (getContactEdit && getContactEdit.Contact_Telephone_Number) || '',
      adress: (getContactEdit && getContactEdit.Contact_Adress) || '',
      kvkk: (getContactEdit && getContactEdit.Contact_KVKK) || '',
      youtubeadress: (getContactEdit && getContactEdit.Contact_Youtube) || '',
      linkedinadress: (getContactEdit && getContactEdit.Contact_Linkedin) || '',
      whatsappNumber: (getContactEdit && getContactEdit.WhatsappNumber) || '',
    },
    validationSchema: Yup.object({
      adress: Yup.string().required("Lütfen Adres Giriniz")
            }),
    onSubmit: (values) => {
        const updateOrder = {
          ID: users ? users[0].ID : 0,
          Mega_Title: values.title,
          Contact_Email: values.email,
          Contact_Facebook: values.fbadress,
          Contact_Instagram: values.instagramadress,
          Contact_Twitter:values.twitteradress,
          Contact_Linkedin:values.linkedinadress,
          Contact_KVKK:values.kvkk,
          Contact_Telephone_Number:values.gsmNumber,
          Contact_Adress:values.adress,
          Contact_Youtube:values.youtubeadress,
          WhatsappNumber:values.whatsappNumber

        };
       
        dispatch(onUpdateConcat(updateOrder));
        //  validation.resetForm();
    
          setTimeout(() => dispatch(onGetContact()), 300)
    
       
    
          showToast(true)
    
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.mega,
  }));

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetContact());
      
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContactEdit(users[0]);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users)) {
      setContactEdit(users[0]);
    }
  }, [users]);


  function showToast(type) {
    console.log("type123",type)
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Bilgileriniz Güncellenmiştir"
    } else{
       ele = "error"
       message = getFalseDesc
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="İletişim Bilgileri" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>İletişim Bilgileri</CardTitle>

                  <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                    <Row>
                   
                        <div className="mb-3">
                          <Label htmlFor="productname">Adres</Label>
                          <Input
                            id="adress"
                            name="adress"
                            type="text"
                            className="form-control"
                            placeholder="Adres"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.adress || ""}
                            invalid={
                              validation.touched.adress && validation.errors.adress ? true : false
                            }
                          />
                          {validation.touched.adress && validation.errors.adress ? (
                            <FormFeedback type="invalid">{validation.errors.adress}</FormFeedback>
                          ) : null}
                         
                        </div>
                      

                        <div className="mb-3">
                          <Label htmlFor="price">Telefon Numarası</Label>
                          <Input
                            id="gsmNumber"
                            name="gsmNumber"
                            type="text"
                            className="form-control"
                            placeholder="Telefon Numarası"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.gsmNumber || ""}
                            invalid={
                              validation.touched.gsmNumber && validation.errors.gsmNumber ? true : false
                            }
                          />
                          {validation.touched.gsmNumber && validation.errors.adgsmNumberess ? (
                            <FormFeedback type="invalid">{validation.errors.gsmNumber}</FormFeedback>
                          ) : null}
                        
                        </div>

                     
                        <div className="mb-3">
                          <Label htmlFor="price">Eposta</Label>
                          <Input
                            id="price"
                            name="email"
                            type="text"
                            className="form-control"
                            placeholder="Eposta Adresi"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                         
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="price">Facebook Adresi</Label>
                          <Input
                            id="price"
                            name="fbadress"
                            type="text"
                            className="form-control"
                            placeholder="Facebook Adresi"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.fbadress || ""}
                            invalid={
                              validation.touched.fbadress && validation.errors.fbadress ? true : false
                            }
                          />
                          {validation.touched.fbadress && validation.errors.fbadress ? (
                            <FormFeedback type="invalid">{validation.errors.fbadress}</FormFeedback>
                          ) : null}
                         
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="price">Linkedin Adresi</Label>
                          <Input
                            id="price"
                            name="linkedinadress"
                            type="text"
                            className="form-control"
                            placeholder="Twitter Adresi"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.linkedinadress || ""}
                            invalid={
                              validation.touched.linkedinadress && validation.errors.linkedinadress ? true : false
                            }
                          />
                          {validation.touched.linkedinadress && validation.errors.linkedinadress ? (
                            <FormFeedback type="invalid">{validation.errors.linkedinadress}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="price">Twitter Adresi</Label>
                          <Input
                            id="price"
                            name="twitteradress"
                            type="text"
                            className="form-control"
                            placeholder="Twitter Adresi"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.twitteradress || ""}
                            invalid={
                              validation.touched.twitteradress && validation.errors.twitteradress ? true : false
                            }
                          />
                          {validation.touched.twitteradress && validation.errors.twitteradress ? (
                            <FormFeedback type="invalid">{validation.errors.twitteradress}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="price">Instagram Adresi</Label>
                          <Input
                            id="price"
                            name="instagramadress"
                            type="text"
                            className="form-control"
                            placeholder="Instagram Adresi"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.instagramadress || ""}
                            invalid={
                              validation.touched.instagramadress && validation.errors.instagramadress ? true : false
                            }
                          />
                          {validation.touched.instagramadress && validation.errors.instagramadress ? (
                            <FormFeedback type="invalid">{validation.errors.instagramadress}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="price">Youtube Adresi</Label>
                          <Input
                            id="youtubeadress"
                            name="youtubeadress"
                            type="text"
                            className="form-control"
                            placeholder="Youtube Adresi"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.youtubeadress || ""}
                            invalid={
                              validation.touched.youtubeadress && validation.errors.youtubeadress ? true : false
                            }
                          />
                          {validation.touched.youtubeadress && validation.errors.youtubeadress ? (
                            <FormFeedback type="invalid">{validation.errors.youtubeadress}</FormFeedback>
                          ) : null}
                        </div>


                        <div className="mb-3">
                          <Label htmlFor="price">WP Numarası</Label>
                          <Input
                            id="whatsappNumber"
                            name="whatsappNumber"
                            type="text"
                            className="form-control"
                            placeholder="WP Numarası"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.whatsappNumber || ""}
                            invalid={
                              validation.touched.whatsappNumber && validation.errors.whatsappNumber ? true : false
                            }
                          />
                          {validation.touched.whatsappNumber && validation.errors.whatsappNumber ? (
                            <FormFeedback type="invalid">{validation.errors.whatsappNumber}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="price">Kvkk</Label>
                          <Input
                            id="kvkk"
                            name="kvkk"
                            type="textarea"
                            className="form-control"
                            placeholder="Kvkk"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.kvkk || ""}
                            invalid={
                              validation.touched.kvkk && validation.errors.kvkk ? true : false
                            }
                          />
                          {validation.touched.kvkk && validation.errors.kvkk ? (
                            <FormFeedback type="invalid">{validation.errors.kvkk}</FormFeedback>
                          ) : null}
                        </div>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Kaydet
                      </Button>
                      <Button type="submit" color="secondary" className=" ">
                        İptal
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>

        
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
