import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
  deleteMainSlider as onDeleteMainSlider,
  addMainSlider as onAddMainSlider,
  updateMainSlider as onUpdateMainSlider,
  getMainPageSlider as onGetMainPageSlider,
} from "store/contacts/actions";

import {
  Name
}
  from "./EcommerceOrderCol";
  import 'react-dropzone-uploader/dist/styles.css'
  import Dropzone from 'react-dropzone-uploader';

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import axios from "axios";

function EcommerceOrder() {

  //meta title
  document.title = "Slider | BUHARA - Admin Paneli";

  const [getIsActive, setIsActive] = useState(false);
  const [getFileNameBanner, setFileNameBanner] = useState(null);

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://buharaadmin.stechomeyazilim.info:8099/uploadfile', body }
  }
   
  // handle the status of the file upload
  const handleChangeStatusBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileNameBanner(result.file.filename)

        }
      }
    }
  }


  

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [getEditSlider, setEditSlider] = useState(null);

  const [getIsEditAddProduct, setIsEditAddProduct] = useState(false);
  const [getIsEditAddRecipes, setIsEditAddRecipes] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (getEditSlider && getEditSlider.MainPage_Slider_Title) || '',
      shortDesc: (getEditSlider && getEditSlider.MainPage_Slider_ShortDesc) || '',
      sortRecipes: (getEditSlider && parseInt(getEditSlider.MainPage_Slider_Sort)) || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Başlığı Giriniz"),
      shortDesc: Yup.string().required("Kısa Açıklama Giriniz"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateSlider = {
          ID: getEditSlider ? getEditSlider.ID : 0,
          MainPage_Slider_Title: values.title,
          MainPage_Slider_ShortDesc: values.shortDesc,
          MainPage_Slider_Sort : parseInt(values.sortRecipes),
         MainPage_Slider_Image : getFileNameBanner,
          MainPage_SliderActive: getIsActive
        };
        // update order
        dispatch(onUpdateMainSlider(updateSlider));
        validation.resetForm();
      } else {
        const newOrder = {
          MainPage_Slider_Title: values["title"],
          MainPage_Slider_ShortDesc: values["shortDesc"],
          MainPage_Slider_Sort : parseInt(values["sortRecipes"]),
          MainPage_Slider_Image : getFileNameBanner,
          MainPage_SliderActive: getIsActive
        };
        // save new order
        dispatch(onAddMainSlider(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { orders } = useSelector(state => ({
    orders: state.contacts.mainPageSlider,
  }));

  useEffect(() => {
   if (orders && !orders.length) {
      dispatch(onGetMainPageSlider());
    }
  }, [dispatch, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {
    const order = arg;
    setEditSlider(order)
    setOrder(order);
    setIsEdit(true);

    setFileNameBanner(order.MainPage_Slider_Image)
    setIsActive(order.MainPage_SliderActive)
    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.ID) {
      dispatch(onDeleteMainSlider(order.ID));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const [getOrderProduct, setOrderProduct] = useState(null);

  
  const handleAddRecipes = arg => {
    const order = arg;
    setOrderProduct(order);
    setIsEditAddRecipes(true);

    _getRecipesList();


    toggleAddRecipes()
  }

  const handleAddProduct = arg => {
    const order = arg;
    setOrderProduct(order);
    setIsEditAddProduct(true);

    _getProductList();


    toggleAddProduct()
  }


  const toggleAddProduct = () => {
    if (getIsEditAddProduct) {
      setModal(false);
      setIsEditAddProduct(false);
      setOrderProduct(null);
    } else {
      setIsEditAddProduct(true);
    }
  }

  const toggleAddRecipes = () => {
    if (getIsEditAddRecipes) {
      setModal(false);
      setIsEditAddProduct(false);
      setIsEditAddRecipes(false);
      setOrderProduct(null);
    } else {
      setIsEditAddRecipes(true);

    }
  }



  const handleDeleteRecipe = async(item) => {
    try{

      console.log("item123",item.ID)
        
      const updateSlider = {
        Recipes_ID: null,
     }


       await axios.patch(`https://buharaapi.stechomeyazilim.info:5210/patchMainSlider/${item.ID}`, updateSlider).then(async(res) => {
 
       console.log("klsdklfm",res)
       dispatch(onGetMainPageSlider());

 
     
       })
   
   }catch (err) {
       console.log("postBlogInRecipespostBlogInRecipes",err)
     }
};



  const handleDeleteProduct = async(item) => {
    try{

      console.log("item123",item.ID)
        
      const updateSlider = {
        Products_ID: null,
     }


       await axios.patch(`https://buharaapi.stechomeyazilim.info:5210/patchMainSlider/${item.ID}`, updateSlider).then(async(res) => {
 
       console.log("klsdklfm",res)
       dispatch(onGetMainPageSlider());

 
     
       })
   
   }catch (err) {
       console.log("postBlogInRecipespostBlogInRecipes",err)
     }
};


  const columns = useMemo(
    () => [
      {
        Header: "Görsel",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.MainPage_Slider_Image ? (
              <div className="avatar-xl">
               {cellProps.MainPage_Slider_Title != null ? <span className="avatar-title rounded-circle">
                  {cellProps.MainPage_Slider_Title.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className=" avatar-xl"
                  src={'https://buharasubdomain.stechomeyazilim.info/' +  cellProps.MainPage_Slider_Image}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: 'BAŞLIK',
        accessor: 'MainPage_Slider_Title',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
   
      {
        Header: "Ürün",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => {

          return(
          <>
            {cellProps.Products != null ? (
              <Link               
              to="#"
              className="text-success"
              onClick={() => {
                handleDeleteProduct(cellProps);
              }}
              >
               {cellProps.Products.Products_Name  != null ? 
               <span className="avatar-title ">
                  {cellProps.Products.Products_Name}
                </span> : null}
              </Link>
            ) : (
              null
            )}
          </>
        )},
      },

     

      {
        Header: "Tarif",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => {

          return(
          <>
            {cellProps.Recipes != null ? (
              <Link               
              to="#"
              className="text-success"
              onClick={() => {
                handleDeleteRecipe(cellProps);
              }}
              >
               {cellProps.Recipes.Recipes_Title  != null ? 
               <span className="avatar-title ">
                  {cellProps.Recipes.Recipes_Title}
                </span> : null}
              </Link>
            ) : (
              null
            )}
          </>
        )},
      },


      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleAddProduct(orderData);
                }}
              >
                  Ürün
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleAddRecipes(orderData);
                }}
              >
                  Tarif
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  const [getSelectProduct, setSelectProduct] = useState([]);
  const [getProductList, setProductList] = useState([]);
  const [getRecipesList, setRecipesList] = useState([]);


  const setChoiceProduct = async(arg) => {
    
    console.log("kslndfcclk",arg.target.value)
       setSelectProduct(arg.target.value);
    
    }

    const _getProductList = async () => {
      setProductList([])
      await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getProductList/select/`)
      .then((res) => {
        setProductList(res.data)
      })
    }

    const _getRecipesList = async () => {
      setProductList([])
      await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getRecipes/select/`)
      .then((res) => {
        setRecipesList(res.data)
      })
    }


    const [getWsText, setWsText] = useState(null);

    const _addProducts = async() => {
      try{
      
           const updateSlider = {
            Products_ID: getSelectProduct,
          }

            await axios.patch(`https://buharaapi.stechomeyazilim.info:5210/patchMainSlider/${getOrderProduct.ID}`, updateSlider).then(async(res) => {
      
            console.log("klsdklfm",res)
            dispatch(onGetMainPageSlider());
      
            toggleAddProduct()
            })

      
      }catch (err) {
          console.log("postBlogInRecipespostBlogInRecipes",err)
        }
      
        }

     const _addRecipes = async() => {
          try{
              
               const updateSlider = {
                Recipes_ID: getSelectProduct,
              }
    
                await axios.patch(`https://buharaapi.stechomeyazilim.info:5210/patchMainSlider/${getOrderProduct.ID}`, updateSlider).then(async(res) => {
          
                dispatch(onGetMainPageSlider());
          
                toggleAddRecipes()
                })
    
          
          }catch (err) {
              console.log("postBlogInRecipespostBlogInRecipes",err)
            }
          
            }

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Yönetim" breadcrumbItem="Slider" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    isAddMainPage={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={getIsEditAddRecipes} toggle={toggleAddRecipes}>
            <ModalHeader toggle={toggleAddRecipes} tag="h4">
              {!!getIsEditAddRecipes ? "Tarif Düzenle" : "Tarif Ekle"}
            </ModalHeader>
            <ModalBody>
             
                  
            <div className="mb-3">
                      <Label className="form-label">Tarifi Seçiniz</Label>
                      <Input
                        name="questionText"
                        type="select"
                        className="form-select"
                        onChange={(text) => setChoiceProduct(text)}
                        value={
                          getWsText
                        }>
                        <option value="">Lütfen Seçim Yapınız</option>

                        {getRecipesList.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Recipes_Title}</option>
                        ))}
                       
                      </Input>
              
              
                    </div>
                                   

                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        onClick={()=> _addRecipes()}
                        className="btn btn-success save-user"
                      >
                        Tarifi Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              
            </ModalBody>
          </Modal>

          <Modal isOpen={getIsEditAddProduct} toggle={toggleAddProduct}>
            <ModalHeader toggle={toggleAddProduct} tag="h4">
              {!!getIsEditAddProduct ? "Ürün Düzenle" : "Ürün Ekle"}
            </ModalHeader>
            <ModalBody>
             
                  
            <div className="mb-3">
                      <Label className="form-label">Ürünü Seçiniz</Label>
                      <Input
                        name="questionText"
                        type="select"
                        className="form-select"
                        onChange={(text) => setChoiceProduct(text)}
                        value={
                          getWsText
                        }>
                       <option value="">Lütfen Seçim Yapınız</option>

                        {getProductList.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Products_Name}</option>
                        ))}
                       
                      </Input>
                  
                    </div>
                                   

                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        onClick={()=> _addProducts()}
                        className="btn btn-success save-user"
                      >
                        Ürünü Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              
            </ModalBody>
          </Modal>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Slider Düzenle" : "Slider Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >

<div className="mb-3">
                      <Label className="form-label">Başlık</Label>
                      <Input
                        name="title"
                        type="text"
                        placeholder="Başlık"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title || ""}
                        invalid={
                          validation.touched.title && validation.errors.title ? true : false
                        }
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                      ) : null}
                    </div>
                  
                    <div className="mb-3">
                      <Label className="form-label">Kısa Açıklama</Label>
                      <Input
                        name="shortDesc"
                        type="text"
                        placeholder="Kısa Açıklama"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.shortDesc || ""}
                        invalid={
                          validation.touched.shortDesc && validation.errors.shortDesc ? true : false
                        }
                      />
                      {validation.touched.shortDesc && validation.errors.shortDesc ? (
                        <FormFeedback type="invalid">{validation.errors.shortDesc}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Sıralama</Label>
                      <Input
                        name="sortRecipes"
                        type="number"
                        placeholder="Sıralama"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.sortRecipes || ""}
                        invalid={
                          validation.touched.sortRecipes && validation.errors.sortRecipes ? true : false
                        }
                      />
                      {validation.touched.sortRecipes && validation.errors.sortRecipes ? (
                        <FormFeedback type="invalid">{validation.errors.sortRecipes}</FormFeedback>
                      ) : null}
                    </div>

                <Row>
                <Col xs={12} className="mb-3">
                            <label htmlFor="resume">Görsel(800*600)</label>
                         <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatusBanner}
          maxFiles={1}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          canRemove={false}
          PreviewComponent={Preview}
          accept="image/*"
        />
                
                          </Col>
                </Row>

          

                <Row>
                <label htmlFor="resume"> Mevcut Eklenen Resim</label>

                <Col xs={12} className="mb-3">
                            <img
                  className=" avatar-xl"
                  src={'https://buharasubdomain.stechomeyazilim.info/' +  getFileNameBanner}
                  alt=""
                />
                          </Col>
                </Row>

               

                <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>

                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;