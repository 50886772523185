import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_POPUP,
  GET_WORKSHOP,
  GET_CourseSchedulesMega,
  GET_WorkshopSessionMega,
  GET_TeacherMega,
  GET_EVENT,
  GET_DYNAMICFORM,
  GET_SLIDER,
  GET_PROJECTSNEW,
  GET_NEWS,
  GET_MEGA,
  GET_CONTACT,
  GET_AUTHORITY,
  GET_WORKSHOP2,
  GET_WORKSHOP2_SUCCESS,
  GET_GALLERY,
  GET_GALLERY_SUCCESS,
  GET_REFERANCES,
  ADD_REFERANCES,
  GET_SUBPROJECT,
  GET_RECIPES_REVIEW,

  UPDATE_WORKSHOP2,
  UPDATE_SUBDOMAIN_SUCCESS,
  UPDATE_AUTH,
  UPDATE_CONTACT,
  UPDATE_SUCCESS_COURSESCHEDULES,
  UPDATE_WORKSHOPSESSION_SUCCESS,
  UPDATE_WORKSHOP2_SUCCESS,
  UPDATE_GALLERY,
  ADD_LESSONS_TIME,

  UPDATE_LESSONS_TIME,
  GET_TEACHER_WORKSHOP,
  GET_REFERANCES_SUCCESS,
  ADD_WORKSHOP2,
  ADD_WORKSHOP2_SUCCESS,
  ADD_APPSETTING,
  ADD_WORKSHOPSUBDOMAIN,
  ADD_COURSE,
  ADD_MEGA,
  ADD_NEWS,
  ADD_PROJECT,
  ADD_SLIDER,
  ADD_POPUP,
  UPDATE_NEWS,
  UPDATE_PROJECT,
  UPDATE_SLIDER,
  UPDATE_TEACHER,
  UPDATE_WORKSHOPSESSION,
  UPDATE_COURSESCHEDULES,
  UPDATE_SUBDOMAIN,
  UPDATE_POPUP,
  GET_POPUP_SUCCESS,
  GET_WORKSHOP_SUCCESS,
  GET_WORKSHOPSESSION_SUCCESS,
  GET_APPLICATION_SUCCESS,
  GET_SLIDER_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_NEWS_SUCCESS,
  GET_MEGA_SUCCESS,
  GET_TEACHER_SUCCESS,
  GET_DYNAMIC_SUCCESS,
  GET_AUTH_SUCCESS,
  GET_AUTHUPDATE_SUCCESS,
  UPDATE_SUCCESS_TEACHER,
  UPDATE_SUCCESS_SLIDER,
  UPDATE_SUCCESS_PROJECT,
  UPDATE_SUCCESS_NEWS,
  UPDATE_SUCCESS_MEGA,
  UPDATE_MEGA,
  UPDATE_SUCCESS_GALLERY,

  UPDATE_REFERANCES,
  UPDATE_REFERANCES_SUCCESS,

  GET_SUBPROJECT_SUCCESS,
  GET_TEACHERWORKSHOP_SUCCESS,

  ADD_LESSONSTIME_SUCCESS,
  ADD_REFERANCES_SUCCESS,
  UPDATE_LESSONSTIME_SUCCESS,

  GET_CLASSATTENDANCE,
  GET_CLASSATTENDANCE_SUCCESS,

  GET_CLASSES,
  GET_CLASSES_SUCCESS,
  UPDATE_DYNAMICFORM,
  UPDATE_DYNAMICFORM_SUCCESS,
  ADD_DYNAMIC_FORM,
  ADD_DYNAMICFORM_SUCCESS,
  GET_DYNAMICFORM_EXTRA,
  GET_DYNAMICFORMEXTRA_SUCCESS,
  GET_DYNAMICFORM_EXTRA_EXTRA,
  GET_DYNAMICFORMEXTRAEXTRA_SUCCESS,

  DELETE_MEGA,
  ADD_MEGA_SUCCESS,
  DELETE_MEGA_SUCCESS,

  DELETE_NEWS_SUCCESS,
  DELETE_NEWS,

  DELETE_SLIDER,
  DELETE_SLIDER_SUCCESS,

  DELETE_POPUP,
  DELETE_POPUP_SUCCESS,

  DELETE_REFERANCES,
  DELETE_REFERANCES_SUCCESS,

  ADD_GALLERY,
  ADD_GALLERY_SUCCESS,

  DELETE_GALLERY,
  DELETE_GALLERY_SUCCESS,

  ADD_GALLERYMULTIPLE,
  ADD_GALLERYMULTIPLE_SUCCESS,

  DELETE_COURSESCHEDULES,
  DELETE_COURSESCHEDULES_SUCCESS,

  DELETE_DYNAMICFORMEXTRA,
  DELETE_DYNAMICFORMEXTRA_SUCCESS,

  DELETE_DYNAMICFORM,
  DELETE_DYNAMICFORM_SUCCESS,

  DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  DELETE_DYNAMICFORMEXTRAEXTRA,
  GET_RECIPES_REVIEW_SUCCESS,

  GET_MAINPAGESLIDER,
  GET_MAINPAGESLIDER_SUCCESS,
  GET_CERTIFICA,
  GET_CERTIFICA_SUCCESS,
  
  ADD_CERTIFICA,
  ADD_CERTIFICA_SUCCESS,

  UPDATE_REVIEW,
  UPDATE_REVIEW_SUCCESS,
  UPDATE_RECIPES,
  UPDATE_RECIPES_SUCCESS,

  UPDATE_MAINSLIDER,
  UPDATE_MAINSLIDER_SUCCESS,

  ADD_MAINSLIDER,
  ADD_MAINSLIDER_SUCCESS,

  DELETE_MAINSLIDER,
  DELETE_MAINSLIDER_SUCCESS,

  DELETE_PROJECTS,
  DELETE_PROJECTS_SUCCESS,

  GET_PRODUCT_CATEGORY,
  GET_PRODUCT_CATEGORY_SUCCESS,

  ADD_PRODUCTCATEGORY,
  ADD_PRODUCTCATEGORY_SUCCESS,

  UPDATE_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY_SUCCESS,

  UPDATE_RECIPESCATEGORY,
  UPDATE_RECIPESCATEGORY_SUCCESS,

  ADD_RECIPESCATEGORY,
  ADD_RECIPESCATEGORY_SUCCESS,

  GET_RECIPESCATEGORY,
  GET_RECIPESCATEGORY_SUCCESS,

  DELETE_RECIPES,
  DELETE_RECIPES_SUCCESS,

  DELETE_CERTIFICA,
  DELETE_CERTIFICA_SUCCESS,

  UPDATE_CERTIFICA,
  UPDATE_CERTIFICA_SUCCESS,

  ADD_RECIPES,
  ADD_RECIPES_SUCCESS,
  GET_RECIPES,
  GET_RECIPES_SUCCESS,

  GET_PRODUCTS,
GET_PRODUCTS_SUCCESS,
UPDATE_PRODUCTS,
UPDATE_PRODUCTS_SUCCESS,
ADD_PRODUCTS,
ADD_PRODUCTS_SUCCESS,
ADD_SLIDER_SUCCESS,
ADD_NEWS_SUCCESS,

DELETE_PRODUCT,
DELETE_PRODUCT_SUCCESS,
DELETE_PRODUCTCATEGORY_SUCCESS,
DELETE_PRODUCTCATEGORY,

DELETE_WORKSHOP2,
DELETE_WORKSHOP2_SUCCESS,

DELETE_RECIPES_CATEGORY,
DELETE_RECIPES_CATEGORY_SUCCESS
} from "./actionTypes"


export const getRecipes = user => ({
  type: GET_RECIPES,
  payload: user,
})

export const getRecipesSuccess = user => ({
  type: GET_RECIPES_SUCCESS,
  payload: user,
})

export const addRecipes = user => ({
  type: ADD_RECIPES,
  payload: user,
})

export const addRecipesSuccess = user => ({
  type: ADD_RECIPES_SUCCESS,
  payload: user,
})


export const deleteRecipes = user => ({
  type: DELETE_RECIPES,
  payload: user,
})

export const deleteRecipesSuccess = (user,user2) => ({
  type: DELETE_RECIPES_SUCCESS,
  payload: user2,
})


export const deleteProductCategory = user => ({
  type: DELETE_PRODUCTCATEGORY,
  payload: user,
})

export const deleteProductCategorySuccess = (user,user2) => ({
  type: DELETE_PRODUCTCATEGORY_SUCCESS,
  payload: user2,
})

export const deleteProduct = user => ({
  type: DELETE_PRODUCT,
  payload: user,
})

export const deleteProductSuccess = user => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: user,
})

export const deleteCertifica = user => ({
  type: DELETE_CERTIFICA,
  payload: user,
})

export const deleteCertificaSuccess = user => ({
  type: DELETE_CERTIFICA_SUCCESS,
  payload: user,
})

export const updateCertifica = user => ({
  type: UPDATE_CERTIFICA,
  payload: user,
})

export const updateCertificaSuccess = user => ({
  type: UPDATE_CERTIFICA_SUCCESS,
  payload: user,
})


export const updateRecipesReview = user => ({
  type: UPDATE_REVIEW,
  payload: user,
})

export const updateRecipesReviewSuccess = user => ({
  type: UPDATE_REVIEW_SUCCESS,
  payload: user,
})


export const deleteRecipesReview = user => ({
  type: DELETE_RECIPES_REVIEW,
  payload: user,
})

export const deleteWorkshop2 = user => ({
  type: DELETE_WORKSHOP2,
  payload: user,
})

export const deleteWorkshop2Success = (user,user2) => ({
  type: DELETE_WORKSHOP2_SUCCESS,
  payload: user2,
})

export const deleteRecipesCategory = user => ({
  type: DELETE_RECIPES_CATEGORY,
  payload: user,
})

export const deleteRecipesCategorySuccess = user => ({
  type: DELETE_RECIPES_CATEGORY_SUCCESS,
  payload: user,
})


export const addRecipesCategory = user => ({
  type: ADD_RECIPESCATEGORY,
  payload: user,
})

export const addRecipesCategorySuccess = user => ({
  type: ADD_RECIPESCATEGORY_SUCCESS,
  payload: user,
})


export const getRecipesCategory = user => ({
  type: GET_RECIPESCATEGORY,
  payload: user,
})

export const getRecipesCategorySuccess = user => ({
  type: GET_RECIPESCATEGORY_SUCCESS,
  payload: user,
})


export const updateRecipesCategory = user => ({
  type: UPDATE_RECIPESCATEGORY,
  payload: user,
})

export const updateRecipesCategorySuccess = user => ({
  type: UPDATE_RECIPESCATEGORY_SUCCESS,
  payload: user,
})

export const updateProductCategory = user => ({
  type: UPDATE_PRODUCT_CATEGORY,
  payload: user,
})

export const updateProductCategorySuccess = user => ({
  type: UPDATE_PRODUCT_CATEGORY_SUCCESS,
  payload: user,
})

export const deleteProjects = user => ({
  type: DELETE_PROJECTS,
  payload: user,
})

export const deleteProjectsSuccess = (user,user2) => ({
  type: DELETE_PROJECTS_SUCCESS,
  payload: user2,
})

export const updateRecipes = user => ({
  type: UPDATE_RECIPES,
  payload: user,
})

export const updateRecipesSuccess = user => ({
  type: UPDATE_RECIPES_SUCCESS,
  payload: user,
})




export const addProductCategory = user => ({
  type: ADD_PRODUCTCATEGORY,
  payload: user,
})

export const addProductCategorySuccess = user => ({
  type: ADD_PRODUCTCATEGORY_SUCCESS,
  payload: user,
})



export const addMainSlider = user => ({
  type: ADD_MAINSLIDER,
  payload: user,
})

export const addMainSliderSucces = user => ({
  type: ADD_MAINSLIDER_SUCCESS,
  payload: user,
})

export const addCertifica = user => ({
  type: ADD_CERTIFICA,
  payload: user,
})

export const addCertifcaSuccess = user => ({
  type: ADD_CERTIFICA_SUCCESS,
  payload: user,
})


export const addMegaSuccess = user => ({
  type: ADD_MEGA_SUCCESS,
  payload: user,
})

export const updateDynamicFormSuccess = user => ({
  type: UPDATE_DYNAMICFORM_SUCCESS,
  payload: user,
})


export const updateDynamicForm = user => ({
  type: UPDATE_DYNAMICFORM,
  payload: user,
})

export const getClasses = () => ({
  type: GET_CLASSES,
})


export const getProducts = user => ({
  type: GET_PRODUCTS,
  payload: user,
})

export const getProductsSuccess = user => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: user,
})


export const updateProducts = user => ({
  type: UPDATE_PRODUCTS,
  payload: user,
})

export const updateProductsSuccess = user => ({
  type: UPDATE_PRODUCTS_SUCCESS,
  payload: user,
})

export const addProducts = user => ({
  type: ADD_PRODUCTS,
  payload: user,
})

export const addProductsSuccess = user => ({
  type: ADD_PRODUCTS_SUCCESS,
  payload: user,
})

export const getProductCategory = user => ({
  type: GET_PRODUCT_CATEGORY,
  payload: user,
})

export const getProductCategorySuccess = user => ({
  type: GET_PRODUCT_CATEGORY_SUCCESS,
  payload: user,
})


export const getCertifica = user => ({
  type: GET_CERTIFICA,
  payload: user,
})

export const getCertificaSuccess = user => ({
  type: GET_CERTIFICA_SUCCESS,
  payload: user,
})



export const getRecipesReview = user => ({
  type: GET_RECIPES_REVIEW,
  payload: user,
})


export const getDynamicFormExtraExtraSuccess = user => ({
  type: GET_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  payload: user,
})

export const updateMainSlider = user => ({
  type: UPDATE_MAINSLIDER,
  payload: user,
})

export const updateMainSliderSuccess = user => ({
  type: UPDATE_MAINSLIDER_SUCCESS,
  payload: user,
})

export const getMainPageSlider = user => ({
  type: GET_MAINPAGESLIDER,
  payload: user,
})


export const getMainPageSliderSuccess = user => ({
  type: GET_MAINPAGESLIDER_SUCCESS,
  payload: user,
})


export const getDynamicFormExtraSuccess = user => ({
  type: GET_DYNAMICFORMEXTRA_SUCCESS,
  payload: user,
})


export const getClassesSuccess = user => ({
  type: GET_CLASSES_SUCCESS,
  payload: user,
})

export const getClassAttendanceSuccess = user => ({
  type: GET_CLASSATTENDANCE_SUCCESS,
  payload: user,
})


export const updateLessonsTimeSuccess = user => ({
  type: UPDATE_LESSONSTIME_SUCCESS,
  payload: user,
})


export const getTeacherWorkshopSuccess = teacher => ({
  type: GET_TEACHERWORKSHOP_SUCCESS,
  payload: teacher,
})

export const getRecipesReviewSuccess = user => ({
  type: GET_RECIPES_REVIEW_SUCCESS,
  payload: user,
})

export const getSubProjectSuccess = teacher => ({
  type: GET_SUBPROJECT_SUCCESS,
  payload: teacher,
})


export const getReferancesSuccess = teacher => ({
  type: GET_REFERANCES_SUCCESS,
  payload: teacher,
})


export const updateGallerySuccess = user => ({
  type: UPDATE_SUCCESS_GALLERY,
  payload: user,
})


export const getAuthority = () => ({
  type: GET_AUTHORITY,
})




export const updateReferances = user => ({
  type: UPDATE_REFERANCES,
  payload: user,
})
export const updateMega = user => ({
  type: UPDATE_MEGA,
  payload: user,
})

export const updateMegaSuccess = news => ({
  type: UPDATE_SUCCESS_MEGA,
  payload: news,
})

export const updateNewsSuccess = news => ({
  type: UPDATE_SUCCESS_NEWS,
  payload: news,
})

export const updateSuccessProject = teacher => ({
  type: UPDATE_SUCCESS_PROJECT,
  payload: teacher,
})

export const updateSliderSuccess = teacher => ({
  type: UPDATE_SUCCESS_SLIDER,
  payload: teacher,
})


export const updateTeacherSuccess = teacher => ({
  type: UPDATE_SUCCESS_TEACHER,
  payload: teacher,
})

export const updateSuccessCourseSchedules = teacher => ({
  type: UPDATE_SUCCESS_COURSESCHEDULES,
  payload: teacher,
})



export const getAuthUpdateSuccess = teacher => ({
  type: GET_AUTHUPDATE_SUCCESS,
  payload: teacher,
})

export const getAuthSuccess = teacher => ({
  type: GET_AUTH_SUCCESS,
  payload: teacher,
})
export const getDynamicSuccess = teacher => ({
  type: GET_DYNAMIC_SUCCESS,
  payload: teacher,
})

export const getTeacherSuccess = teacher => ({
  type: GET_TEACHER_SUCCESS,
  payload: teacher,
})

export const getGallerySuccess = mega => ({
  type: GET_GALLERY_SUCCESS,
  payload: mega,
})


export const getMegaSuccess = mega => ({
  type: GET_MEGA_SUCCESS,
  payload: mega,
})




export const getWorkShop2 = news => ({
  type: GET_WORKSHOP2,
  payload: news,
})


export const getNewsSuccess = news => ({
  type: GET_NEWS_SUCCESS,
  payload: news,
})

export const getProjectSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects,
})

export const getSliderSuccess = slider => ({
  type: GET_SLIDER_SUCCESS,
  payload: slider,
})

export const getApplicationSuccess = workshop => ({
  type: GET_APPLICATION_SUCCESS,
  payload: workshop,
})
export const getWorkshopSessionSuccess = workshop => ({
  type: GET_WORKSHOPSESSION_SUCCESS,
  payload: workshop,
})
export const getWorkshopSuccess = workshop => ({
  type: GET_WORKSHOP_SUCCESS,
  payload: workshop,
})


export const updateWorkShopSessionSuccess = user => ({
  type: UPDATE_WORKSHOPSESSION_SUCCESS,
  payload: user,
})

export const updateSubdomainSuccess = user => ({
  type: UPDATE_SUBDOMAIN_SUCCESS,
  payload: user,
})

export const updateAuth = user => ({
  type: UPDATE_AUTH,
  payload: user,
})

export const updateContact = user => ({
  type: UPDATE_CONTACT,
  payload: user,
})

export const updateNews = user => ({
  type: UPDATE_NEWS,
  payload: user,
})


export const updateProject = user => ({
  type: UPDATE_PROJECT,
  payload: user,
})

export const updateSlider = user => ({
  type: UPDATE_SLIDER,
  payload: user,
})


export const getWorkshop2Success = popup => ({
  type: GET_WORKSHOP2_SUCCESS,
  payload: popup,
})


export const getPopupSuccess = popup => ({
  type: GET_POPUP_SUCCESS,
  payload: popup,
})


export const updateTeacher = user => ({
  type: UPDATE_TEACHER,
  payload: user,
})


export const updateWorkshop2 = user => ({
  type: UPDATE_WORKSHOP2,
  payload: user,
})

export const updateWorkShop2Success = user => ({
  type: UPDATE_WORKSHOP2_SUCCESS,
  payload: user,
})

export const updateWorkshopSession = user => ({
  type: UPDATE_WORKSHOPSESSION,
  payload: user,
})



export const updateCourseSchedules = user => ({
  type: UPDATE_COURSESCHEDULES,
  payload: user,
})

export const updateSubdomain = user => ({
  type: UPDATE_SUBDOMAIN,
  payload: user,
})


export const updatePopup = user => ({
  type: UPDATE_POPUP,
  payload: user,
})


export const getClassAttendance = () => ({
  type: GET_CLASSATTENDANCE,
})


export const getTeacherWorkshopMega = () => ({
  type: GET_TEACHER_WORKSHOP,
})

export const getReferances = () => ({
  type: GET_REFERANCES,
})

export const getContactMega = () => ({
  type: GET_CONTACT,
})

export const getMegaMega = () => ({
  type: GET_MEGA,
})

export const getNews = () => ({
  type: GET_NEWS,
})

export const getSubProject = news => ({
  type: GET_SUBPROJECT,
  news,
})


export const getProjectsNew = () => ({
  type: GET_PROJECTSNEW,
})


export const getSlider = () => ({
  type: GET_SLIDER,
})

export const getDynamicForm = () => ({
  type: GET_DYNAMICFORM,
})


export const getEventMega = () => ({
  type: GET_EVENT,
})

export const getTeacherMega = () => ({
  type: GET_TeacherMega,
})

export const getWorkshopSessionMega = () => ({
  type: GET_WorkshopSessionMega,
})


export const getWorkShop = () => ({
  type: GET_WORKSHOP,
})

export const getPopup = () => ({
  type: GET_POPUP,
})

export const getCourseSchedulesMega = () => ({
  type: GET_CourseSchedulesMega,
})



export const getGallery = () => ({
  type: GET_GALLERY,
})

export const getUsers = () => ({
  type: GET_USERS,
})

export const getDynamicFormExtra = users => ({
  type: GET_DYNAMICFORM_EXTRA,
  users,
})

export const getDynamicFormExtraExtra = users => ({
  type: GET_DYNAMICFORM_EXTRA_EXTRA,
  users,
})


export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})


export const addDynamicFormSuccess = user => ({
  type: ADD_DYNAMICFORM_SUCCESS,
  payload: user,
})

export const addDynamicForm = user => ({
  type: ADD_DYNAMIC_FORM,
  payload: user,
})

export const addLessonsTime = user => ({
  type: ADD_LESSONS_TIME,
  payload: user,
})

export const addReferances = user => ({
  type: ADD_REFERANCES,
  payload: user,
})

export const updateSuccessReferances = user => ({
  type: UPDATE_REFERANCES_SUCCESS,
  payload: user,
})

export const addNewWorkshop2 = user => ({
  type: ADD_WORKSHOP2,
  payload: user,
})

export const addNewWorkshop2Success = user => ({
  type: ADD_WORKSHOP2_SUCCESS,
  payload: user,
})

export const addAppSetting = user => ({
  type: ADD_APPSETTING,
  payload: user,
})

export const addWorkshopSubdomain = user => ({
  type: ADD_WORKSHOPSUBDOMAIN,
  payload: user,
})

export const addCourse = user => ({
  type: ADD_COURSE,
  payload: user,
})

export const addNewMega = user => ({
  type: ADD_MEGA,
  payload: user,
})

export const addNews = user => ({
  type: ADD_NEWS,
  payload: user,
})

export const addNewsSuccess = user => ({
  type: ADD_NEWS_SUCCESS,
  payload: user,
})

export const addProject = user => ({
  type: ADD_PROJECT,
  payload: user,
})

export const addSlider = user => ({
  type: ADD_SLIDER,
  payload: user,
})

export const addSliderSuccess = user => ({
  type: ADD_SLIDER_SUCCESS,
  payload: user,
})


export const addPopup = user => ({
  type: ADD_POPUP,
  payload: user,
})

export const addNewUser = user => ({
  type: ADD_NEW_USER,
  payload: user,
})

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addSuccessLessonsTime = user => ({
  type: ADD_LESSONSTIME_SUCCESS,
  payload: user,
})


export const addSuccessReferances = user => ({
  type: ADD_REFERANCES_SUCCESS,
  payload: user,
})




export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
})

export const getUserProfileSuccess = userProfile => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile,
})

export const getUserProfileFail = error => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error,
})

export const updateLessonsTime = user => ({
  type: UPDATE_LESSONS_TIME,
  payload: user,
})

export const updateGallery = user => ({
  type: UPDATE_GALLERY,
  payload: user,
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteMainSlider = user => ({
  type: DELETE_MAINSLIDER,
  payload: user,
})

export const deleteMainSliderSuccess = user => ({
  type: DELETE_MAINSLIDER_SUCCESS,
  payload: user,
})

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user,
})

export const deleteUserSuccess = (user,user2) => ({
  type: DELETE_USER_SUCCESS,
  payload: user2,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})


export const deleteMega = user => ({
  type: DELETE_MEGA,
  payload: user,
})

export const deleteMegaSuccess = (user,ID) => ({
  type: DELETE_MEGA_SUCCESS,
  payload: user,
  ID:ID
})

export const deleteNews = user => ({
  type: DELETE_NEWS,
  payload: user,
})

export const deleteNewsSuccess = (user,ID) => ({
  type: DELETE_NEWS_SUCCESS,
  payload: user,
  ID:ID
})

export const deleteSlider = user => ({
  type: DELETE_SLIDER,
  payload: user,
})

export const deleteSliderSuccess = (user,ID) => ({
  type: DELETE_SLIDER_SUCCESS,
  payload: user,
  ID:ID
})

export const deletePopup = user => ({
  type: DELETE_POPUP,
  payload: user,
})

export const deletePopupSuccess = (user,ID) => ({
  type: DELETE_POPUP_SUCCESS,
  payload: user,
  ID:ID
})


export const deleteReferances = user => ({
  type: DELETE_REFERANCES,
  payload: user,
})

export const deleteReferancesSuccess = (user,ID) => ({
  type: DELETE_REFERANCES_SUCCESS,
  payload: user,
  ID:ID
})

export const addGallerySuccess = user => ({
  type: ADD_GALLERY_SUCCESS,
  payload: user,
})

export const addGallery = user => ({
  type: ADD_GALLERY,
  payload: user,
})

export const deleteGallery = user => ({
  type: DELETE_GALLERY,
  payload: user,
})

export const deleteGallerySuccess = (user,ID) => ({
  type: DELETE_GALLERY_SUCCESS,
  payload: user,
  ID:ID
})


export const addGalleryMultipleSuccess = user => ({
  type: ADD_GALLERYMULTIPLE_SUCCESS,
  payload: user,
})

export const addGalleryMultiple = user => ({
  type: ADD_GALLERYMULTIPLE,
  payload: user,
})

export const deleteCourseSchedules = user => ({
  type: DELETE_COURSESCHEDULES,
  payload: user,
})

export const deleteCourseSchedulesSuccess = (user,ID) => ({
  type: DELETE_COURSESCHEDULES_SUCCESS,
  payload: user,
  ID:ID
})

export const deleteDynamicFormExtra = user => ({
  type: DELETE_DYNAMICFORMEXTRA,
  payload: user,
})

export const deleteDynamicExtraFormSuccess = (user,ID) => ({
  type: DELETE_DYNAMICFORMEXTRA_SUCCESS,
  payload: user,
  ID:ID
})

export const deleteDynamicForm = user => ({
  type: DELETE_DYNAMICFORM,
  payload: user,
})

export const deleteDynamicFormSuccess = (user,ID) => ({
  type: DELETE_DYNAMICFORM_SUCCESS,
  payload: user,
  ID:ID
})



export const deleteDynamicFormExtraExtra = user => ({
  type: DELETE_DYNAMICFORMEXTRAEXTRA,
  payload: user,
})


export const deleteDynamicExtraExtraFormSuccess = (user,ID) => ({
  type: DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  payload: user,
  ID:ID
})
