import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Status, Date } from "./contactlistCol";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import "jspdf-autotable";
import * as XLSX from 'xlsx';

import {
  getDynamicForm as onGetDynamicForm,
  addDynamicForm as onaddDynamicForm,
  updateDynamicForm as onUpdateDynamicForm,
  deleteDynamicForm as onDeleteDynamicForm,
} from "store/contacts/actions";
import { isEmpty } from "lodash";
import moment from "moment";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//redux
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

const ContactsList = props => {

  //meta title
  document.title = "Tarif Ürünleri | Buhara ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [getEdit, setEdit] = useState();

  const [getUnitID, setUnitID] = useState();
  const [loading, setLoading] = useState(false);

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (getEdit && getEdit.Products_Name) || "",
      amount: (getEdit && getEdit.Products_Amount) || "",
      code: (getEdit && getEdit.Products2_Code) || "",
      unitText:(getEdit && getEdit.Products2_UnitText) || "",
      unit2: (getEdit && getEdit.Products2_UnitText2) || "",
      piece: (getEdit && getEdit.Products_Piece) || "",
    },
    validationSchema: Yup.object({
   
    }),
    onSubmit: values => {
      var localDate = moment().local();

      if (isEdit) {
        const updateUser = {
          ID: getEdit.ID,
          Products_Name: values.name,
          Products_Owner: getIsOwner,
        //  Products_Image:row.hazırlamasüresi,
          Products_IsActive:getActive,
       //   Products_EngName:row.ürüniçeriği,
       //   Products_ImageEng:row.kullanımipuçları,
          Products2_Code:values.code,
          Products_Units_ID:getUnitID,
          Products_Amount:values.amount,
          Products_Image: getFileNameLogo,
          Products2_UnitText:values.unitText,
          Products2_UnitText2:values.unit2,
          Products_Piece:values.piece,
                };
                console.log("updateUser",updateUser)
        // update user
        dispatch(onUpdateDynamicForm(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Products_Name: values["name"],
          Products_Owner: getIsOwner,
        //  Products_Image:row.hazırlamasüresi,
          Products_IsActive:getActive,
       //   Products_EngName:row.ürüniçeriği,
       //   Products_ImageEng:row.kullanımipuçları,
          Products2_Code:values["code"],
       //   Products_Units_ID:getUnitID,
          Products_Amount:values["amount"],
          Products_Image: getFileNameLogo,
          Products2_UnitText:values["unitText"],
          Products2_UnitText2:values["unit2"],
          Products_Piece:values["piece"],
        };
        // save new user
        console.log("newUser1231",newUser)
        dispatch(onaddDynamicForm(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.dynamic,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getEditForm, setEditForm] = useState(false);
  const [getActive, setActive] = useState(false);

  const [getIsOwner, setIsOwner] = useState(false);
  const  [getFileNameLogo, setFileNameLogo] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Görsel",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.Products_Image ? (
              <div className="avatar-xl">
               {cellProps.Products_Name != null ? <span className="avatar-title rounded-circle">
                  {cellProps.Products_Name.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className=" avatar-xl"
                  src={'https://buharasubdomain.stechomeyazilim.info/' +  cellProps.Products_Image}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Ürün Adı",
        accessor: "Products_Name",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Kod",
        accessor: "Products2_Code",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
    
      {
        Header: "Buhara Ürünü?",
        accessor: "Products_Owner",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
  
      {
        Header: "Durum",
        accessor: "Products_IsActive",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: 'Miktar',
        accessor: 'Products_Amount',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },

      {
        Header: 'Birim',
        accessor: 'Products2_UnitText',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },

    

      {
        Header: 'Ürün Miktar',
        accessor: 'Products_Piece',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
     
      {
        Header: 'Birim',
        accessor: 'Products2_UnitText2',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },


      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
             

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );
  

  const _getAuthData= async () => {

    await axios.get(`https://buharaapi.stechomeyazilim.info:5210/productsUnit/select/`)
    .then((res) => {
      console.log("şsmdlşf",res.data)
      //setUnit(res.data)
    })
  }


  useEffect(() => {
    //_getAuthData()
    if (users && !users.length) {
      dispatch(onGetDynamicForm());
      setIsEdit(false);
   }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    setEditForm(user)
    setContact(user);
    setIsEdit(true);

    setActive(user.Products_IsActive)
    setIsOwner(user.Products_Owner)

    setFileNameLogo(user.Products_Image)

    setEdit(user)
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteDynamicForm(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  const handleClick = () => {
    document.getElementById('fileInput').click();
  };

  const arrayBufferToBinaryString = (buffer) => {
    const binaryArray = new Uint8Array(buffer);
    const binaryString = binaryArray.reduce((data, byte) => {
      return data + String.fromCharCode(byte);
    }, '');
    return binaryString;
  };


  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://buharaadmin.stechomeyazilim.info:8099/uploadfile', body }
  }
   
  // handle the status of the file upload
  const handleChangeStatusLogo = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileNameLogo(result.file.filename)

        }
      }
    }
  }


  function showToast(type,type2) {

    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = type2 + " Başarıyla Veri Giriş İşleminiz Gerçekleşmiştir!"
    } else{
       ele = "error"
       message = type2 +  " Aynı Verileri Eklediniz!"
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

   const handleFileUpload = async(e) => {
    try{
    setLoading(true)
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryString = arrayBufferToBinaryString(event.target.result);
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      
      // Assuming the first row contains headers
      const headers = data[0];

  
      const formattedData = data.slice(1).map((row) => {
        const rowData = {};
        row.forEach((cell, index) => {
          rowData[headers[index]] = cell;
        });
        return rowData;
      });

      const existingCodes = new Set(users.map((user) => user.Products2_Code));


      for (const row of formattedData) {

        if (existingCodes.has(row.id)) {
          showToast(false,row.adı)

          continue; // Aynı Products2_Code'ye sahip olanları atla
        }

        else{
          console.log("lmşsdşf",row.birim2)
        const newOrder = {
          Products_Name: row.adı,
          Products_Owner: row.ürünüm == "hayır" ? false : true,
        //  Products_Image:row.hazırlamasüresi,
          Products_IsActive:true,
       //   Products_EngName:row.ürüniçeriği,
       //   Products_ImageEng:row.kullanımipuçları,
          Products2_Code:row.id,
       //   Products_Units_ID:row.birim == "adet" ? 1 : row.birim == "dilim" ? 2 : row.birim == "diş" ? 3 : row.birim == "çay bardağı" ? 4 : row.birim == "su bardağı" ? 5 :row.birim == "tatlı kaşığı" ? 6:row.birim == "yemek kaşığı" ? 7:row.birim == "çay kaşığı" ? 8:row.birim == "gram" ? 9 : row.birim == "ml" ? 10: null,
          Products_Amount:row.miktar,
          Products_Piece:parseInt(row.miktar2),
          Products2_UnitText:row.birim,
          Products2_UnitText2:row.birim2,
        };
    
        showToast(true,row.adı)


        console.log("lşsldf",newOrder)
        dispatch(onaddDynamicForm(newOrder));


      }
// Yeni siparişi kaydet
}

// Veri alımını güncelle
 dispatch(onGetDynamicForm());

 setTimeout(() => setLoading(false), 1000)

 // Yükleme durumunu false yaparak yükleme ekranını kapat
};


reader.readAsArrayBuffer(file);

}catch (err) {
  console.log("postBlogInRecipespostBlogInRecipes",err)
}


   }
     
  const _setChoice = arg => {
    setUnitID(arg.target.value)
   }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Ürünler" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

                {loading && <div className="loading-screen">Yükleniyor...</div>}

                <button  className="btn-rounded  mb-2 me-2"  onClick={handleClick}>Excel Dosyasını Yükle</button>
      <input
        id="fileInput"
        type="file"
        accept=".xlsx, .xls"
        style={{ display: 'none' }}
        onChange={handleFileUpload}
      />


                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddDynamicForm={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal size="xl" isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Ürün Düzenle" : "Ürün Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                         
                          <Col xl={6}>
                            <div className="mb-3">
                              <Label className="form-label">Ürün Adı</Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Ürün Adı"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                    validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                                validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <Col xl={6}>

                            <div className="mb-3">
                              <Label className="form-label">Ürün Kodu</Label>
                              <Input
                                name="code"
                                type="text"
                                placeholder="Ürün Kodu"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.code || ""}
                                invalid={
                                  validation.touched.code &&
                                    validation.errors.code
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.code &&
                                validation.errors.code ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.code}
                                </FormFeedback>
                              ) : null}
                            </div>
                         </Col>

                         <Col xl={6}>
                            <div className="mb-3">
                              <Label className="form-label">Ürün Miktarı</Label>
                              <Input
                                name="amount"
                                type="number"
                                placeholder="Ürün Miktarı"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.amount || ""}
                                invalid={
                                  validation.touched.amount &&
                                    validation.errors.amount
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.amount &&
                                validation.errors.amount ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.amount}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>
                         
                            
                            <Col xl={6}>
                            <div className="mb-3">
                              <Label className="form-label">Adet</Label>
                              <Input
                                name="piece"
                                type="number"
                                placeholder="Adet"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.piece || ""}
                                invalid={
                                  validation.touched.piece &&
                                    validation.errors.piece
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.piece &&
                                validation.errors.piece ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.piece}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

   <Col xl={6}>
                            <div className="mb-3">
                              <Label className="form-label">UnitText</Label>
                              <Input
                                name="unitText"
                                type="text"
                                placeholder="Birim"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.unitText || ""}
                                invalid={
                                  validation.touched.unitText &&
                                    validation.errors.unitText
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.unitText &&
                                validation.errors.unitText ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.unitText}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>
                       

                            <Col xl={6}>
                            <div className="mb-3">
                              <Label className="form-label">Unit2</Label>
                              <Input
                                name="unit2"
                                type="text"
                                placeholder="Birim2"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.unit2 || ""}
                                invalid={
                                  validation.touched.unit2 &&
                                    validation.errors.unit2
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.unit2 &&
                                validation.errors.unit2 ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.unit2}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <Col xl={6}>
                            <div className="d-flex">
                    <Label className="form-label">Buhara Ürünü</Label>

                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsOwner}
                              onChange={() =>
                                setIsOwner(!getIsOwner)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>

                          <Label className="form-label">Aktiflik Durumu</Label>

                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch2"
                              className="switch switch-info"
                              defaultChecked={getActive}
                              onChange={() =>
                                setActive(!getActive)
                              }
                            />
                            <label
                              htmlFor="square-switch2"
                              data-on-label="Aktif"
                              data-off-label="Pasif"
                            />
                          </div>
                       
                        </div>
                        </Col>



                <Row xs={12}>
                <Col xs={12} className="mb-3">
                            <label htmlFor="resume">Görseli(800*600)</label>
                         <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatusLogo}
          maxFiles={1}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          canRemove={false}
          PreviewComponent={Preview}
          accept="image/*"
        />
                
                          </Col>
                </Row>

                        
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
