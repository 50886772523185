import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';
import "jspdf-autotable";
import * as XLSX from 'xlsx';
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  deleteNews as onDeleteNews,
  addNews as onAddNews,
  updateNews as onUpdateNews,
  getNews as onGetNews,
} from "store/contacts/actions";

import {
  Name,
  Status,
  Date
}
  from "./EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";

import moment from "moment";
import axios from "axios";

function EcommerceOrder() {

  //meta title
  document.title = "Blog | Buhara - Admin Paneli";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  
  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [getEditNews, setEditNews] = useState(null);
  const [getIsActive, setIsActive] = useState(false);

  const [getBlog, setBlog] = useState([]);
  const [getBlogCatID, setBlogID] = useState(false);
  const [getFileName, setFileName] = useState(null);


  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://buharaadmin.stechomeyazilim.info:8099/uploadfile', body }
  }
   
  // handle the status of the file upload
  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      blogTitle: (getEditNews && getEditNews.Blog_Title) || '',
      //newsPhoto: (getEditNews && getEditNews.News_PhotoLink) || ''
      blogShort: (getEditNews && getEditNews.Blog_Desc) || ''
     },
    validationSchema: Yup.object({
      blogTitle: Yup.string().required("Blog başlığını giriniz"),
      blogShort: Yup.string().required("Blog kısa açıklama giriniz")
    }),
    onSubmit: (values) => {
      if (isEdit) {
        var localDate = moment().local();

        const updateOrder = {
          ID: order ? order.ID : 0,
          Blog_Title: values.blogTitle,
          Blog_Desc: values.blogShort,
          Blog_Image: getFileName,
          //Blog_Detail_Image: getIsActive,
          Blog_IsActive: getIsActive,
          Blog_Category_ID : getBlogCatID
        };
        // update order
        dispatch(onUpdateNews(updateOrder));
        validation.resetForm();
      } else {
        var localDate = moment().local();


        const newOrder = {
          Blog_Title: values["blogTitle"],
          Blog_Desc: values["blogShort"],
          Blog_Image: getFileName,
          //Blog_Detail_Image: getIsActive,
          Blog_IsActive: getIsActive,
          Blog_Category_ID : getBlogCatID,
          Created_DateTime:localDate
        };

        // save new order
        dispatch(onAddNews(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { users } = useSelector(state => ({
    users: state.contacts.news,
  }));

  useEffect(() => {
   if (users && !users.length) {
      dispatch(onGetNews());
      setIsEdit(false);
   }
  }, [dispatch, users]);

  useEffect(() => {
    setOrderList(users);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setOrderList(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const _getBlogCategory= async () => {

    await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getBlogCategory/select/`)
      .then((res) => {
        setBlog(res.data)
      })
  }

  useEffect(() => {
    _getBlogCategory()
  }, [getBlog]);

  

  const handleOrderClick = arg => {
    const order = arg;
    setEditNews(order)
    setOrder(order);

    setIsEdit(true);
    setIsActive(order.Blog_IsActive)
    setFileName(order.Blog_Image)

    setBlogID(order.Blog_Category_ID)

    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {

    if (order && order.ID) {
      console.log("şmsdşlf",order.ID)

      dispatch(onDeleteNews(order.ID));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Blog Görseli",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.Blog_Image ? (
              <div className="avatar-xl">
                {cellProps.Blog_Title != null ?  <span className="avatar-title rounded-circle">
                  {cellProps.Blog_Title.charAt(0)}
                </span>  : null }
              </div>
            ) : (
              <div>
                <img
                  className="avatar-xl"
                  src={'https://buharasubdomain.stechomeyazilim.info/' + cellProps.Blog_Image}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Blog Başlığı",
        accessor: "Blog_Title",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },

      {
        Header: "Blog Kategori",
        accessor: "Blog_Category.Blog_Category_Title",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },

      {
        Header: "Eklenme Tarihi",
        disableFilters: true,
        accessor: "Created_DateTime",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: 'Aktiflik Durumu',
        disableFilters: true,
        accessor: 'Blog_IsActive',
        filterable: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );



  const arrayBufferToBinaryString = (buffer) => {
    const binaryArray = new Uint8Array(buffer);
    const binaryString = binaryArray.reduce((data, byte) => {
      return data + String.fromCharCode(byte);
    }, '');
    return binaryString;
  };


  function showToast(type,type2) {

    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = type2 + " Başarıyla Veri Giriş İşleminiz Gerçekleşmiştir!"
    } else{
       ele = "error"
       message = type2 +  " Aynı Verileri Eklediniz!"
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryString = arrayBufferToBinaryString(event.target.result);
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      
      // Assuming the first row contains headers
      const headers = data[0]


      const formattedData = data.slice(1).map((row) => {
        const rowData = {};
        row.forEach((cell, index) => {
          rowData[headers[index]] = cell;
        });
        return rowData;
      });

      const existingCodes = new Set(users.map((user) => user.Blog_Title));


      for (const row of formattedData) {

        if (existingCodes.has(row.baslik)) {
          showToast(false,row.baslik)

          continue; // Aynı Products2_Code'ye sahip olanları atla
        }

        else{


        var localDate = moment().local();

        const newUser = {
          Blog_Title:row.baslik,
          Blog_Desc: row.aciklama,
          Created_DateTime: localDate,
          Blog_IsActive:true
        };
        // save new user
        dispatch(onAddNews(newUser));
  

        showToast(true,row.baslik)


      }
      // Yeni siparişi kaydet
      }
      
      // Veri alımını güncelle
       dispatch(onGetNews());
      
       setTimeout(() => setLoading(false), 1000)
      
       // Yükleme durumunu false yaparak yükleme ekranını kapat
      };
      
    reader.readAsArrayBuffer(file);
  }
     
  const _setChoiceBlog = arg => {
    setBlogID(arg.target.value)
   }


   const handleClick = () => {
    document.getElementById('fileInput').click();
  };
   
  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Buhara Baharat" breadcrumbItem="Blog" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>

                <button 
                color="success"
      className="btn-rounded  mb-2 me-2" 
      onClick={handleClick}>Excel Dosyasını Yükle</button>
      <input
        id="fileInput"
        type="file"
        accept=".xlsx, .xls"
        style={{ display: 'none' }}
        onChange={handleFileUpload}
      />


                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddBlog={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Blog Düzenle" : "Blog Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                        
                    <div className="mb-3">
                      <Label className="form-label">Başlık</Label>
                      <Input
                        name="blogTitle"
                        type="text"
                        placeholder="Başlık"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.blogTitle || ""}
                        invalid={
                          validation.touched.blogTitle && validation.errors.blogTitle ? true : false
                        }
                      />
                      {validation.touched.blogTitle && validation.errors.blogTitle ? (
                        <FormFeedback type="invalid">{validation.errors.blogTitle}</FormFeedback>
                      ) : null}
                    </div>
                   


                    <div className="mb-3">
                      <Label className="form-label">Blog Kısa Açıklama</Label>
                      <Input
                        name="blogShort"
                        type="textarea"
                        style={{ height: '200px' }} // Yüksekliği burada ayarlayabilirsiniz

                        placeholder="Kısa Açıklama"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.blogShort || ""}
                        invalid={
                          validation.touched.blogShort && validation.errors.blogShort ? true : false
                        }
                      />
                      {validation.touched.blogShort && validation.errors.blogShort ? (
                        <FormFeedback type="invalid">{validation.errors.blogShort}</FormFeedback>
                      ) : null}
                    </div>

                
                    <div className="mb-3">
                      <Label className="form-label">Blog Kategori Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        defaultValue={getBlogCatID}
                        onChange={(text) => _setChoiceBlog(text)}
                        value={
                          getBlogCatID
                        }>
                      <option value="">Lütfen Seçim Yapınız</option>
                        {getBlog.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Blog_Category_Title}</option>
                        ))}
                       
                      </Input>
                     
                    </div>



               
                        <Row>
                <Col xs={12} className="mb-3">
                            <label htmlFor="resume">Dosya(800*600)</label>
                         <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          
          canRemove={false}
          PreviewComponent={Preview}
          accept="image/*,audio/*,video/*"
        />
                
                          </Col>
                </Row>


                {getFileName != null ? <Row>
                <label htmlFor="resume"> Mevcut Eklenen Resim</label>

                <Col xs={12} className="mb-3">
                            <img
                  className=" avatar-xl"
                  src={'https://buharasubdomain.stechomeyazilim.info/' +  getFileName}
                  alt=""
                />
                          </Col>
                </Row> : null}

                <div className="d-flex">
                          <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                        
                       
                        </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;