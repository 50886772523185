import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import {  Email ,Date } from "./contactlistCol";
import {ExportToExcel} from './ExportToExcel'
import { Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import jsPDF from "jspdf";
import "jspdf-autotable";


import Dropzone from 'react-dropzone-uploader';
import {Base64} from 'js-base64';
import moment from "moment";

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import { Status } from "pages/Popup/PopupList/contactlistCol";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Kullanıcı Listesi | BUHARA ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();

  const [getUsersTypeID, setUsersTypeID] = useState(null);

  const [getUserText, setUserText] = useState();

  const [getUserType, setUserType] = useState([]);

  const [getIsCheckActive, setIsCheckActive] = useState(false);

  
  
  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nameSurname: (contactEdit && contactEdit.NameSurname) || "",
      password: (contactEdit && contactEdit.Users_Password) || "",
      usersGender: (contactEdit && contactEdit.Users_Gender) || "",
      phoneNumber:(contactEdit && contactEdit.GsmNumber) || "",
    },
    validationSchema: Yup.object({
      nameSurname: Yup.string(3).required("Kullanıcı Adını Giriniz"),
      password: Yup.string(3).required("Şifreyi Giriniz"),
    }),
    onSubmit: values => {
      if (isEdit) {
        var localDate = moment().local();

        const updateUser = {
          ID: contactEdit.ID,
          NameSurname:values.nameSurname,
          GsmNumber:  values.phoneNumber,
          Users_Type_ID:getUsersTypeID,
          UsersPhoto:getFilePhote,
          Users_IsActive:getIsCheckActive,
          Users_Password:values.password,
        };
        dispatch(onUpdateUser(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          NameSurname:values["nameSurname"],
          GsmNumber:  values["phoneNumber"],
          CreatedDateTime: localDate,
          Users_Type_ID:getUsersTypeID,
          UsersPhoto:getFilePhote,
          Users_IsActive:getIsCheckActive,
          Users_Password:values["password"],
          
        };
        // save new user
        dispatch(onAddNewUser(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.users,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [getFilePhote, setFilePhote] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.UsersPhoto ? (
              <div className="avatar-md">
              {cellProps.NameSurname != null ?  <span className="avatar-title rounded-circle">
                  {cellProps.NameSurname.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-md"
                  src={'https://buharasubdomain.stechomeyazilim.info/' + cellProps.UsersPhoto}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Kullanıcı Adı",
        accessor: "NameSurname",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: "Telefon Numarası",
        accessor: "GsmNumber",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
   
      {
        Header: 'Kayıt Tarihi',
        accessor: 'CreatedDateTime',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
    
      {
        Header: 'Aktiflik Durumu',
        accessor: 'Users_IsActive',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData= async () => {
  
    await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getUserTypeBuhara/select/`)
    .then((res) => {
      setUserType(res.data)
    })
  }

  useEffect(() => {
    _getAuthData()
    if (users && !users.length) {
      dispatch(onGetUsers());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
   // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

    setIsEdit(true);
    setIsCheckActive(user.Users_IsActive)
    setFilePhote(user.UsersPhoto)
    setUsersTypeID(user.Users_Type_ID)
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteUser(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };


   
   const _setChoiceAuthType = arg => {
    setUsersTypeID(arg.target.value)
   }

   const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }


  const keyField = "id";
  const fileName = "myfile"; // here enter filename for your excel file

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Kullanıcı Listesi";
    const headers = [["Adı", "Telefon Numarasi", "İlçe", "Adres", "Mail Adresi"]];

    const data = users.map(elt=> [elt.NameSurname, elt.GsmNumber, elt.District, elt.Adress, elt.MailAdress]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")
  }

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://buharaadmin.stechomeyazilim.info:8099/uploadfile', body }
  }
  
  const handleChangeStatusBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFilePhote(result.file.filename)
        }
      }
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Kullanıcı Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
      
                <ExportToExcel apiData={users} fileName={fileName} />

                <Button
    type="button"
    color="danger"
    className="btn  mb-2 me-2"
    onClick={exportPDF}>
       <i className="mdi mdi-export me-1" />
                     PDF
                </Button>
                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddContactList={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Kullanıcı Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                       
                        
                      
                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Adı</Label>
                              <Input
                                name="nameSurname"
                                label="Kullanıcı Adı"
                                type="text"
                                placeholder="Kullanıcı Adını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.nameSurname || ""}
                                invalid={
                                  validation.touched.nameSurname &&
                                    validation.errors.nameSurname
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.nameSurname &&
                                validation.errors.nameSurname ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.nameSurname}
                                </FormFeedback>
                              ) : null}
                            </div>
                          

                            <div className="mb-3">
                              <Label className="form-label">Şifre</Label>
                              <Input
                                name="password"
                                label="Şifre"
                                type="text"
                                placeholder="Şifreyi Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={
                                  validation.touched.password &&
                                    validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.password &&
                                validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Telefon Numarası</Label>
                              <Input
                                name="phoneNumber"
                                label="Telefon Numarası"
                                type="text"
                                placeholder="Telefon Numaranızı Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phoneNumber || ""}
                                invalid={
                                  validation.touched.phoneNumber &&
                                    validation.errors.phoneNumber
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.phoneNumber &&
                                validation.errors.phoneNumber ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.phoneNumber}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Cinsiyet</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                disabled={true}
                                placeholder="Cinsiyeti Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.usersGender || ""}
                                invalid={
                                  validation.touched.usersGender &&
                                    validation.errors.usersGender
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.usersGender &&
                                validation.errors.usersGender ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.usersGender}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                    <label htmlFor="resume">Kullanıcı Fotoğrafı (800*600)</label>
                         <Dropzone
                         maxFiles={1}
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatusBanner}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          canRemove={true}
          PreviewComponent={Preview}
          accept="image/*"
        />
                    </div>
                     
                    {getFilePhote != null ?  <Row>
                <label htmlFor="resume"> Mevcut Eklenen Resim</label>

                <Col xs={12} className="mb-3">
                            <img
                  className=" avatar-xl"
                  src={'https://buharasubdomain.stechomeyazilim.info/' +  getFilePhote}
                  alt=""
                />
                          </Col>
                </Row> : null}


                            <div className="mb-3">
                      <Label className="form-label">Kullanıcı Tipi Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        defaultValue={getUsersTypeID}
                        onChange={(text) => _setChoiceAuthType(text)}
                        value={
                          getUserText
                        }>
                      <option value="">Lütfen Seçim Yapınız</option>
                        {getUserType.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Users_Type_Title}</option>
                        ))}
                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>



              

                          </Col>
                        </Row>
                        <Row>

<div className="d-flex">
                        <Label className="form-label">Kullanıcı Aktifliği</Label>

<div className="square-switch">
    <input
      type="checkbox"
      id="square-switch1"
      className="switch"
      defaultChecked={getIsCheckActive}
      onChange={() =>
        setIsCheckActive(!getIsCheckActive)
      }
    />
    <label
 htmlFor="square-switch1"
      data-on-label="Aktif"
      data-off-label="Kapalı"
    />
  </div>
  

           
                          </div>

                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
