import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { DELETE_PROJECTS,DELETE_MAINSLIDER,ADD_MAINSLIDER,UPDATE_MAINSLIDER,GET_MAINPAGESLIDER,DELETE_DYNAMICFORM,DELETE_DYNAMICFORMEXTRA,DELETE_COURSESCHEDULES,DELETE_GALLERY,DELETE_NEWS,GET_DYNAMICFORM_EXTRA_EXTRA,GET_DYNAMICFORM_EXTRA,UPDATE_DYNAMICFORM,GET_CLASSES,GET_CLASSATTENDANCE,UPDATE_LESSONS_TIME,GET_TEACHER_WORKSHOP,GET_SUBPROJECT,UPDATE_REFERANCES,ADD_REFERANCES,GET_REFERANCES,ADD_WORKSHOP2,ADD_APPSETTING,ADD_WORKSHOPSUBDOMAIN,UPDATE_GALLERY,GET_GALLERY,UPDATE_WORKSHOP2,UPDATE_MEGA,UPDATE_AUTH,GET_AUTHORITY,UPDATE_CONTACT,GET_CONTACT,UPDATE_WORKSHOPSESSION,UPDATE_SUBDOMAIN,ADD_COURSE,ADD_MEGA,GET_MEGA,GET_NEWS,GET_PROJECTSNEW,GET_SLIDER,GET_DYNAMICFORM,GET_EVENT,GET_TeacherMega,GET_WorkshopSessionMega,GET_CourseSchedulesMega,GET_POPUP,GET_USERS, GET_USER_PROFILE , ADD_NEW_USER , DELETE_USER, UPDATE_USER, GET_WORKSHOP, ADD_POPUP, ADD_SLIDER, ADD_PROJECT, ADD_NEWS, UPDATE_NEWS, UPDATE_TEACHER, UPDATE_COURSESCHEDULES, UPDATE_SLIDER, UPDATE_PROJECT, GET_WORKSHOP2, ADD_LESSONS_TIME, ADD_DYNAMIC_FORM, DELETE_MEGA, DELETE_SLIDER, DELETE_POPUP, DELETE_REFERANCES, ADD_GALLERY, ADD_GALLERYMULTIPLE, DELETE_DYNAMICFORMEXTRAEXTRA, GET_RECIPES_REVIEW, GET_CERTIFICA, ADD_CERTIFICA, UPDATE_REVIEW, UPDATE_RECIPES, GET_PRODUCT_CATEGORY, ADD_PRODUCTCATEGORY, UPDATE_PRODUCT_CATEGORY, UPDATE_RECIPESCATEGORY, ADD_RECIPESCATEGORY, GET_RECIPESCATEGORY, DELETE_RECIPES, DELETE_CERTIFICA, UPDATE_CERTIFICA, ADD_RECIPES, GET_RECIPES, GET_PRODUCTS, ADD_PRODUCTS, UPDATE_PRODUCTS, DELETE_PRODUCTCATEGORY, GET_PRODUCTS_SUCCESS, DELETE_WORKSHOP2, DELETE_RECIPES_CATEGORY } from "./actionTypes"

import {
  getUsersSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  getPopupSuccess,
  getWorkshopSuccess,
  getWorkshopSessionSuccess,
  getApplicationSuccess,
  getSliderSuccess,
  getProjectSuccess,
  getNewsSuccess,
  getMegaSuccess,
  getTeacherSuccess,
  getDynamicSuccess,

  
  getAuthSuccess,
  getAuthUpdateSuccess,
  updateSubdomainSuccess,
  updateSuccessCourseSchedules,
  updateWorkShopSessionSuccess,
  updateTeacherSuccess,
  updateSliderSuccess,
  updateSuccessProject,
  updateNewsSuccess,
  updateMegaSuccess,
  getWorkshop2Success,
  updateWorkShop2Success,
  getGallerySuccess,
  updateGallerySuccess,
  getReferancesSuccess,

  addSuccessReferances,
  updateSuccessReferances,
  getSubProjectSuccess,
  getTeacherWorkshopSuccess,
  addSuccessLessonsTime,
  updateLessonsTimeSuccess,
  getClassAttendanceSuccess,
  getClassesSuccess,
  updateDynamicFormSuccess,
  addDynamicFormSuccess,
  getDynamicFormExtraSuccess,
  getDynamicFormExtraExtraSuccess,

  addMegaSuccess,
  deleteMegaSuccess,

  deleteNewsSuccess,
  deleteSliderSuccess,

  deletePopupSuccess,
  deleteReferancesSuccess,
  addGallerySuccess,
  deleteGallerySuccess,
  deleteCourseSchedulesSuccess,
  deleteDynamicFormSuccess,
  deleteDynamicExtraFormSuccess,

  deleteDynamicExtraExtraFormSuccess,
  getRecipesReviewSuccess,
  getMainPageSliderSuccess,
  getCertificaSuccess,
  addCertifcaSuccess,
  updateRecipesReviewSuccess,
  updateRecipesSuccess,
  updateMainSliderSuccess,
  addMainSliderSucces,
  deleteMainSliderSuccess,
  deleteProjectsSuccess,
  getProductCategorySuccess,
  addProductCategorySuccess,
  updateProductCategorySuccess,
  updateRecipesCategorySuccess,
  addRecipesCategorySuccess,
  getRecipesCategorySuccess,
  deleteRecipesCategorySuccess,
  updateCertificaSuccess,
  deleteCertificaSuccess,
  addRecipesSuccess,
  getRecipesSuccess,
  getProductsSuccess,
  updateProductsSuccess,
  addProductsSuccess,
  addSliderSuccess,
  addNewsSuccess,
  deleteProductCategorySuccess,
  addNewWorkshop2Success,
  deleteWorkshop2Success,
  deleteRecipesSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { deleteNewsFunc,addProducts,updateProducts,getProducts,getRecipes,addRecipes,updateCertifica,deleteCertifica,deleteRecipesCategory,getRecipesCategory,addRecipesCategory,updateRecipesCategory,updateProductCategory,addProductCategory,getProductCategory,deleteProjects,deleteMainSlider,addMainSlider,updateMainSlider,updateRecipes,updateRecipesReview,addCertifica,getCertifica,getMainPageSlider,gecRecipesReviewSelect,deleteDynamicFormExtraExtra,deleteDynamicForm,deleteDynamicFormExtra,deleteCourseSchedules,addMultipleGalleryForm,deleteGallery,addGallerry,deleteReferances,deletePopup,deleteSlider,deleteMega,getDynamicFormExtraExtraMega,getDynamicFormExtraMega,addDynamicForm,updateDynamicForm,getClassesMega,getClassAttendance,updateLessonsTime,addLessonsTime,getTeacherWorkshop,getSubProject,updateReferances,addReferances,getReferances,addWorkshop2,addAppSetting,addNewSubdomain,updateGallery,getGallery,updateWorkShop2,getWorkshop2,updateMega,updateProject,updateSlider,updateAuth,getAuthority,updateContact,getContact,updateTeacher,updateWorkshopSession,updateSubdomain,addCourseMega,updateCourseSchedules,updateNews,addMega,addNews,addProject,addSlider,addPopup,getMegaMega,getNews,getProjectsNew,getSlider,getDynamicFormMega,getEventMega,getTeacherMega,getWorkshopSessionMega,getPopup,getUsers, getUserProfile , addNewUser, updateUser ,deleteUser ,getWorkShop,getCourseSchedulesMega, deleteProductCategory, deleteWorkshop2, deleteRecipes} from "../../helpers/fakebackend_helper"

function* onGetProducts(item) {
  try {
    const response = yield call(getProducts, item.users)
    console.log("lsmfkls",response)
    yield put(getProductsSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onUpdateProducts({ payload: user }) {
  try {
    const response = yield call(updateProducts, user)
    console.log("lksdfnklds",response.value)
    yield put(updateProductsSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onAddProducts({ payload: user }) {

  try {
    const response = yield call(addProducts, user)
    yield put(addProductsSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddRecipes({ payload: user }) {

  try {
    const response = yield call(addRecipes, user)
    yield put(addRecipesSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddProductCategory({ payload: user }) {

  try {
    const response = yield call(addProductCategory, user)
    yield put(addProductCategorySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddMainSlider({ payload: user }) {

  try {
    const response = yield call(addMainSlider, user)

    yield put(addMainSliderSucces(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onDeleteCertifica({ payload: user }) {
  try {
    const response = yield call(deleteCertifica, user)
    console.log("klsmdfklds",response, user)
    yield put(deleteCertificaSuccess(user, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onUpdateCertifica({ payload: user }) {
  try {
    const response = yield call(updateCertifica, user)
    yield put(updateCertificaSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}




function* onDeleteRecipes({ payload: user }) {
  try {
    const response = yield call(deleteRecipes, user)
    console.log("klsmdfklds",response, user)
    yield put(deleteRecipesSuccess(user, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}


function* onDeleteRecipesCategory({ payload: user }) {
  try {
    const response = yield call(deleteRecipesCategory, user)
    console.log("klsmdfklds",response, user)
    yield put(deleteRecipesCategorySuccess(user, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onGetRecipesCategory(item) {
  try {
    const response = yield call(getRecipesCategory, item.users)
    yield put(getRecipesCategorySuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetRecipes(item) {
  try {
    const response = yield call(getRecipes, item.users)
    console.log("lnsdlfknsdklf",response)
    yield put(getRecipesSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onAddRecipesCategory({ payload: user }) {

  try {
    const response = yield call(addRecipesCategory, user)

    yield put(addRecipesCategorySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onUpdateRecipesCategory({ payload: user }) {
  try {
    const response = yield call(updateRecipesCategory, user)
    yield put(updateRecipesCategorySuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateProductCategory({ payload: user }) {
  try {
    const response = yield call(updateProductCategory, user)
    yield put(updateProductCategorySuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateMainSlider({ payload: user }) {
  try {
    const response = yield call(updateMainSlider, user)
    yield put(updateMainSliderSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateRecipes({ payload: user }) {
  try {
    const response = yield call(updateRecipes, user)
    console.log("lksdnklf",response.value[0])
    yield put(updateRecipesSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateRecipesReview({ payload: user }) {
  try {
    const response = yield call(updateRecipesReview, user)
    yield put(updateRecipesReviewSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateDynamicForm({ payload: user }) {
  try {
    const response = yield call(updateDynamicForm, user)
    yield put(updateDynamicFormSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onGetProductCategory(item) {
  try {
    const response = yield call(getProductCategory, item.users)
    yield put(getProductCategorySuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetCertifica(item) {
  try {
    const response = yield call(getCertifica, item.users)
    yield put(getCertificaSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetMainPageSlider(item) {
  try {
    const response = yield call(getMainPageSlider, item.users)
    yield put(getMainPageSliderSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetRecipesReview(item) {
  try {
    const response = yield call(gecRecipesReviewSelect, item.users)
    yield put(getRecipesReviewSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetDynamicFormExtraExtra(item) {
  try {
    const response = yield call(getDynamicFormExtraExtraMega, item.users)
    yield put(getDynamicFormExtraExtraSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetDynamicFormExtra(item) {
  try {
    const response = yield call(getDynamicFormExtraMega, item.users)
    yield put(getDynamicFormExtraSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetClasses() {
  try {
    const response = yield call(getClassesMega)
    yield put(getClassesSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetClassAttendance() {
  try {
    const response = yield call(getClassAttendance)
    yield put(getClassAttendanceSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetTeacherWorkshopMega() {
  try {
    const response = yield call(getTeacherWorkshop)
    yield put(getTeacherWorkshopSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetSubProject(item) {
  try {
    const response = yield call(getSubProject(item.news))
    yield put(getSubProjectSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onUpdateLessonsTime({ payload: user }) {
  try {
    const response = yield call(updateLessonsTime, user)
    yield put(updateLessonsTimeSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onGetAddCertifica({ payload: user }) {

  try {
    const response = yield call(addCertifica, user)

    yield put(addCertifcaSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddLessonsTime({ payload: user }) {

  try {
    const response = yield call(addLessonsTime, user)

    yield put(addSuccessLessonsTime(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddReferances({ payload: user }) {

  try {
    const response = yield call(addReferances, user)

    yield put(addSuccessReferances(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onGetReferances() {
  try {
    const response = yield call(getReferances)
    yield put(getReferancesSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchGallery() {
  try {
    const response = yield call(getGallery)
    yield put(getGallerySuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchAuthority() {
  try {
    const response = yield call(getAuthority)
    yield put(getAuthSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchContact() {
  try {
    const response = yield call(getContact)
    yield put(getMegaSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onUpdateAuth({ payload: user }) {
  try {
    const response = yield call(updateAuth, user)
    yield put(getAuthUpdateSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateReferances({ payload: user }) {
  try {
    const response = yield call(updateReferances, user)
    yield put(updateSuccessReferances(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateProject({ payload: user }) {
  try {
    const response = yield call(updateProject, user)
    yield put(updateSuccessProject(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateContact({ payload: user }) {
  try {
    const response = yield call(updateContact, user)
    yield put(getMegaSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateTeacher({ payload: user }) {
  try {
    const response = yield call(updateTeacher, user)
    yield put(updateTeacherSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateWorkshopSession({ payload: user }) {
  try {
    const response = yield call(updateWorkshopSession, user)
    yield put(updateWorkShopSessionSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateSlider({ payload: user }) {
  try {
    const response = yield call(updateSlider, user)
    yield put(updateSliderSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateSubdomain({ payload: user }) {
  try {
    const response = yield call(updateSubdomain, user)
    yield put(updateSubdomainSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* fetchMega() {
  try {
    const response = yield call(getMegaMega)
    yield put(getMegaSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchNews() {
  try {
    const response = yield call(getNews)
    yield put(getNewsSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchProjects() {
  try {
    const response = yield call(getProjectsNew)
    yield put(getProjectSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchSlider() {
  try {
    const response = yield call(getSlider)
    yield put(getSliderSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetcDynamicFormMega() {
  try {
    const response = yield call(getDynamicFormMega)
    yield put(getDynamicSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetcEventMega() {
  try {
    const response = yield call(getEventMega)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetcTeacherMega() {
  try {
    const response = yield call(getTeacherMega)
    yield put(getTeacherSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshopSessionMega() {
  try {
    const response = yield call(getWorkshopSessionMega)
    yield put(getApplicationSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* fetchCourseSchedulesMega() {
  try {
    const response = yield call(getCourseSchedulesMega)
    yield put(getWorkshopSessionSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshop2() {
  try {
    const response = yield call(getWorkshop2)
    yield put(getWorkshop2Success(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchPopup() {
  try {
    const response = yield call(getPopup)
    console.log("response123",response)
    yield put(getPopupSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshop() {
  try {
    const response = yield call(getWorkShop)
    yield put(getWorkshopSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUsers() {
  try {
    const response = yield call(getUsers)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUserProfile() {
  try {
    const response = yield call(getUserProfile)
    yield put(getUserProfileSuccess(response))
  } catch (error) {
    yield put(getUserProfileFail(error))
  }
}

function* onUpdateCourseSchedules({ payload: user }) {
  try {
    const response = yield call(updateCourseSchedules, user)
    yield put(updateSuccessCourseSchedules(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateNews({ payload: user }) {
  try {
    const response = yield call(updateNews, user)
    yield put(updateNewsSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateGalllery({ payload: user }) {
  try {
    const response = yield call(updateGallery, user)
    yield put(updateGallerySuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateWorkshop2({ payload: user }) {
  try {
    const response = yield call(updateWorkShop2, user)
    console.log("msdşlf",response)
    yield put(updateWorkShop2Success(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateMega({ payload: user }) {
  try {
    const response = yield call(updateMega, user)
    yield put(updateMegaSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const response = yield call(updateUser, user)
    yield put(updateUserSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    const response = yield call(deleteUser, user)
    console.log("klsmdfklds",response, user)
    yield put(deleteUserSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteMainSlider({ payload: user }) {
  try {
    const response = yield call(deleteMainSlider, user)
    console.log("klsmdfklds",response, user)
    yield put(deleteMainSliderSuccess(user, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}




function* onAddDynamicForm({ payload: user }) {

  try {
    const response = yield call(addDynamicForm, user)

    yield put(addDynamicFormSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAdddNewWorkshop2({ payload: user }) {

  try {
    const response = yield call(addWorkshop2, user)

    yield put(addNewWorkshop2Success(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddWorkshopSession({ payload: user }) {

  try {
    const response = yield call(addAppSetting, user)

    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddWorkshopSubdomain({ payload: user }) {

  try {
    const response = yield call(addNewSubdomain, user)

    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onAddNewUser({ payload: user }) {

  try {
    const response = yield call(addNewUser, user)

    console.log("response123",response)
    yield put(addUserSuccess(response.value[0]))
  } catch (error) {
    console.log("error",error)

    yield put(addUserFail(error))
  }
}

function* onAddCourse({ payload: user }) {

  try {
    const response = yield call(addCourseMega, user)

    console.log("response123213",response)
    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onAddNewMega({ payload: user }) {

  try {
    const response = yield call(addMega, user)

    console.log("respocccc13",response.value[0])
    yield put(addMegaSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddNews({ payload: user }) {

  try {
    const response = yield call(addNews, user)

    console.log("response123213",response)
    yield put(addNewsSuccess(response.value[0]))
  } catch (error) {
    console.log("error123",error)

    yield put(addUserFail(error))
  }
}

function* onAddProject({ payload: user }) {

  try {
    const response = yield call(addProject, user)

    console.log("response123213",response)
    yield put(addUserSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onAddSlider({ payload: user }) {

  try {
    const response = yield call(addSlider, user)

    console.log("response123213",response)
    yield put(addSliderSuccess(response.value[0]))
  } catch (error) {
    console.log("error",error)

    yield put(addUserFail(error))
  }
}

function* onAddPopup({ payload: user }) {

  try {
    const response = yield call(addPopup, user)

    yield put(addUserSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onDeleteNews({ payload: user }) {
  try {
    console.log("şömsd111111lccşf",user)

    const response = yield call(deleteNewsFunc, user)
    console.log("user111123",user,response)
    yield put(deleteNewsSuccess(response,user))
  } catch (error) {
    console.log("şömsd11lşf",error)
    yield put(deleteUserFail(error))
  }
}

function* onDeleteWorkshop2({ payload: user }) {
  try {
    const response = yield call(deleteWorkshop2, user)
    console.log("user123",user)
    yield put(deleteWorkshop2Success(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteMega({ payload: user }) {
  try {
    const response = yield call(deleteMega, user)
    console.log("user123",user)
    yield put(deleteMegaSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteProductCategory({ payload: user }) {
  try {
    console.log("msdnklfşş",user)
    const response = yield call(deleteProductCategory, user)
    console.log("user123",user)
    yield put(deleteProductCategorySuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}




function* onDeleteSlider({ payload: user }) {
  try {
    const response = yield call(deleteSlider, user)
    console.log("user123",user)
    yield put(deleteSliderSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeletePopup({ payload: user }) {
  try {
    const response = yield call(deletePopup, user)
    console.log("user123",user)
    yield put(deletePopupSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteReferance({ payload: user }) {
  try {
    const response = yield call(deleteReferances, user)
    console.log("user123",user)
    yield put(deleteReferancesSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}


function* onAddGallery({ payload: user }) {

  try {
    const response = yield call(addGallerry, user)

    yield put(addGallerySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onDeleteGallery({ payload: user }) {
  try {
    const response = yield call(deleteGallery, user)
    yield put(deleteGallerySuccess(user,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteProjects({ payload: user }) {
  try {
    const response = yield call(deleteProjects, user)
    console.log("response123",response,user)
    yield put(deleteProjectsSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddMultipleGallery({ payload: user }) {

  try {
    const response = yield call(addMultipleGalleryForm, user)

   //yield put(addGallerySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onDeleteCourseSchedules({ payload: user }) {
  try {
    const response = yield call(deleteCourseSchedules, user)
    yield put(deleteCourseSchedulesSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicFormExtra({ payload: user }) {
  try {
    const response = yield call(deleteDynamicFormExtra, user)
    yield put(deleteDynamicExtraFormSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicForm({ payload: user }) {
  try {
    const response = yield call(deleteDynamicForm, user)
    yield put(deleteDynamicFormSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicFormExtraExtra({ payload: user }) {
  try {
    const response = yield call(deleteDynamicFormExtraExtra, user)
    yield put(deleteDynamicExtraExtraFormSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* contactsSaga() {
  
  yield takeEvery(DELETE_WORKSHOP2, onDeleteWorkshop2)

  yield takeEvery(GET_PRODUCTS, onGetProducts)

  yield takeEvery(DELETE_PRODUCTCATEGORY, onDeleteProductCategory)

  yield takeEvery(UPDATE_PRODUCTS, onUpdateProducts)
  yield takeEvery(ADD_PRODUCTS, onAddProducts)


  yield takeEvery(GET_RECIPES, onGetRecipes)

  yield takeEvery(ADD_RECIPES, onAddRecipes)

  yield takeEvery(DELETE_CERTIFICA, onDeleteCertifica)
  yield takeEvery(UPDATE_CERTIFICA, onUpdateCertifica)
  yield takeEvery(DELETE_RECIPES, onDeleteRecipes)

  yield takeEvery(DELETE_RECIPES_CATEGORY, onDeleteRecipesCategory)
  yield takeEvery(UPDATE_RECIPESCATEGORY, onUpdateRecipesCategory)
  yield takeEvery(ADD_RECIPESCATEGORY, onAddRecipesCategory)
  yield takeEvery(GET_RECIPESCATEGORY, onGetRecipesCategory)

  yield takeEvery(UPDATE_PRODUCT_CATEGORY, onUpdateProductCategory)

  yield takeEvery(ADD_PRODUCTCATEGORY, onAddProductCategory)

  yield takeEvery(GET_PRODUCT_CATEGORY, onGetProductCategory)

  yield takeEvery(DELETE_PROJECTS, onDeleteProjects)

  yield takeEvery(DELETE_MAINSLIDER, onDeleteMainSlider)

  yield takeEvery(ADD_MAINSLIDER, onAddMainSlider)

  yield takeEvery(UPDATE_MAINSLIDER, onUpdateMainSlider)

  yield takeEvery(UPDATE_RECIPES, onUpdateRecipes)

  yield takeEvery(UPDATE_REVIEW, onUpdateRecipesReview)

  yield takeEvery(ADD_CERTIFICA, onGetAddCertifica)

  yield takeEvery(GET_CERTIFICA, onGetCertifica)

  yield takeEvery(GET_MAINPAGESLIDER, onGetMainPageSlider)

  yield takeEvery(GET_RECIPES_REVIEW, onGetRecipesReview)

  yield takeEvery(DELETE_DYNAMICFORMEXTRAEXTRA, onDeleteDynamicFormExtraExtra)

  yield takeEvery(DELETE_DYNAMICFORM, onDeleteDynamicForm)

  yield takeEvery(DELETE_DYNAMICFORMEXTRA, onDeleteDynamicFormExtra)

  yield takeEvery(DELETE_COURSESCHEDULES, onDeleteCourseSchedules)

  yield takeEvery(ADD_GALLERYMULTIPLE, onAddMultipleGallery)

  yield takeEvery(DELETE_GALLERY, onDeleteGallery)

  yield takeEvery(ADD_GALLERY, onAddGallery)

  yield takeEvery(DELETE_REFERANCES, onDeleteReferance)

  yield takeEvery(DELETE_POPUP, onDeletePopup)

  yield takeEvery(DELETE_SLIDER, onDeleteSlider)

  yield takeEvery(DELETE_NEWS, onDeleteNews)

  yield takeEvery(DELETE_MEGA, onDeleteMega)


  yield takeEvery(GET_DYNAMICFORM_EXTRA_EXTRA, onGetDynamicFormExtraExtra)

  yield takeEvery(GET_DYNAMICFORM_EXTRA, onGetDynamicFormExtra)

  yield takeEvery(ADD_DYNAMIC_FORM, onAddDynamicForm)

  yield takeEvery(UPDATE_DYNAMICFORM, onUpdateDynamicForm)

  yield takeEvery(GET_CLASSES, onGetClasses)

  yield takeEvery(GET_CLASSATTENDANCE, onGetClassAttendance)

  yield takeEvery(UPDATE_LESSONS_TIME, onUpdateLessonsTime)

  yield takeEvery(ADD_LESSONS_TIME, onAddLessonsTime)

  yield takeEvery(GET_TEACHER_WORKSHOP, onGetTeacherWorkshopMega)

  yield takeEvery(GET_SUBPROJECT, onGetSubProject)

  yield takeEvery(UPDATE_REFERANCES, onUpdateReferances)

  yield takeEvery(ADD_REFERANCES, onAddReferances)

  yield takeEvery(GET_REFERANCES, onGetReferances)

  yield takeEvery(ADD_WORKSHOP2, onAdddNewWorkshop2)

  yield takeEvery(ADD_APPSETTING, onAddWorkshopSession)

  yield takeEvery(ADD_WORKSHOPSUBDOMAIN, onAddWorkshopSubdomain)

  yield takeEvery(UPDATE_GALLERY, onUpdateGalllery)

  yield takeEvery(GET_GALLERY, fetchGallery)

  yield takeEvery(UPDATE_WORKSHOP2, onUpdateWorkshop2)

  yield takeEvery(GET_WORKSHOP2, fetchWorkshop2)

  yield takeEvery(UPDATE_MEGA, onUpdateMega)

  yield takeEvery(UPDATE_PROJECT, onUpdateProject)

  yield takeEvery(UPDATE_SLIDER, onUpdateSlider)

  yield takeEvery(UPDATE_COURSESCHEDULES, onUpdateCourseSchedules)

  yield takeEvery(UPDATE_AUTH, onUpdateAuth)

  yield takeEvery(GET_AUTHORITY, fetchAuthority)

  yield takeEvery(UPDATE_CONTACT, onUpdateContact)

  yield takeEvery(GET_CONTACT, fetchContact)

  yield takeEvery(UPDATE_TEACHER, onUpdateTeacher)

  yield takeEvery(UPDATE_WORKSHOPSESSION, onUpdateWorkshopSession)

  yield takeEvery(UPDATE_SUBDOMAIN, onUpdateSubdomain)

  


  yield takeEvery(ADD_COURSE, onAddCourse)

  yield takeEvery(UPDATE_NEWS, onUpdateNews)

  yield takeEvery(ADD_MEGA, onAddNewMega)

  yield takeEvery(ADD_NEWS, onAddNews)

  yield takeEvery(ADD_PROJECT, onAddProject)

  yield takeEvery(ADD_SLIDER, onAddSlider)

  yield takeEvery(ADD_POPUP, onAddPopup)

  yield takeEvery(GET_MEGA, fetchMega)

  yield takeEvery(GET_NEWS, fetchNews)

  yield takeEvery(GET_PROJECTSNEW, fetchProjects)

  yield takeEvery(GET_SLIDER, fetchSlider)

  yield takeEvery(GET_DYNAMICFORM, fetcDynamicFormMega)

  yield takeEvery(GET_EVENT, fetcEventMega)

  yield takeEvery(GET_TeacherMega, fetcTeacherMega)

  yield takeEvery(GET_CourseSchedulesMega, fetchCourseSchedulesMega)

  yield takeEvery(GET_WorkshopSessionMega, fetchWorkshopSessionMega)

  yield takeEvery(GET_WORKSHOP, fetchWorkshop)

  yield takeEvery(GET_POPUP, fetchPopup)

  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
}

export default contactsSaga;
