import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  GET_WORKSHOP2_SUCCESS,
  GET_POPUP_SUCCESS,
  UPDATE_POPUP_SUCCESS,
  UPDATE_SUBDOMAIN_SUCCESS,
  UPDATE_SUCCESS_COURSESCHEDULES,
  UPDATE_WORKSHOPSESSION_SUCCESS,
  UPDATE_SUCCESS_TEACHER,
  UPDATE_SUCCESS_SLIDER,
  UPDATE_SUCCESS_NEWS,
  UPDATE_SUCCESS_MEGA,
  UPDATE_WORKSHOP2_SUCCESS,
  UPDATE_SUCCESS_GALLERY,

  GET_WORKSHOP_SUCCESS,
  GET_WORKSHOPSESSION_SUCCESS,
  GET_APPLICATION_SUCCESS,
  GET_SLIDER_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_NEWS_SUCCESS,
  GET_MEGA_SUCCESS,
  GET_TEACHER_SUCCESS,
  GET_DYNAMIC_SUCCESS,
  GET_CONTACT,
  GET_AUTH_SUCCESS,
  GET_AUTHUPDATE_SUCCESS,
  UPDATE_SUCCESS_PROJECT,
  GET_GALLERY_SUCCESS,

  GET_SUBPROJECT_SUCCESS,
  GET_TEACHERWORKSHOP_SUCCESS,
  ADD_LESSONSTIME_SUCCESS,
  UPDATE_LESSONSTIME_SUCCESS,
  GET_CLASSATTENDANCE_SUCCESS,

  GET_CLASSES_SUCCESS,
  UPDATE_DYNAMICFORM_SUCCESS,
  ADD_DYNAMICFORM_SUCCESS,
  GET_DYNAMICFORMEXTRA_SUCCESS,
  GET_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  ADD_MEGA_SUCCESS,
  DELETE_MEGA_SUCCESS,
  DELETE_NEWS_SUCCESS,
  DELETE_SLIDER_SUCCESS,
  DELETE_POPUP_SUCCESS,
  ADD_GALLERY_SUCCESS,

  DELETE_GALLERY_SUCCESS,
  DELETE_COURSESCHEDULES_SUCCESS,
  DELETE_DYNAMICFORMEXTRA_SUCCESS,
  DELETE_DYNAMICFORM_SUCCESS,

  DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  GET_RECIPES_REVIEW_SUCCESS,
  GET_MAINPAGESLIDER_SUCCESS,
  GET_CERTIFICA_SUCCESS,
  ADD_CERTIFICA_SUCCESS,
  UPDATE_REVIEW_SUCCESS,
  UPDATE_RECIPES_SUCCESS,
  UPDATE_MAINSLIDER_SUCCESS,
  ADD_MAINSLIDER_SUCCESS,
  DELETE_MAINSLIDER_SUCCESS,
  DELETE_PROJECTS_SUCCESS,
  GET_PRODUCT_CATEGORY_SUCCESS,
  ADD_PRODUCTCATEGORY_SUCCESS,
  UPDATE_PRODUCT_CATEGORY_SUCCESS,
  ADD_RECIPESCATEGORY_SUCCESS,
  UPDATE_RECIPESCATEGORY_SUCCESS,
  GET_RECIPESCATEGORY_SUCCESS,
  UPDATE_CERTIFICA_SUCCESS,
  DELETE_CERTIFICA_SUCCESS,
  ADD_RECIPES_SUCCESS,
  GET_RECIPES_SUCCESS,
  GET_PRODUCTS_SUCCESS,
  UPDATE_PRODUCTS_SUCCESS,
  ADD_PRODUCTS_SUCCESS,
  ADD_SLIDER_SUCCESS,
  ADD_NEWS_SUCCESS,
  DELETE_PRODUCTCATEGORY_SUCCESS,
  ADD_WORKSHOP2_SUCCESS,
  DELETE_WORKSHOP2_SUCCESS,
  DELETE_RECIPES_CATEGORY_SUCCESS,
  DELETE_RECIPES_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  forum: [],
  users: [],
  userProfile: {},
  error: {},
  popup: [],
  workshop: [],
  workshopSession: [],
  workshopsessionMega: [],
  slider: [],
  projects: [],
  news:[],
  mega:[],
  teacher:[],
  dynamic:[],
  contact:[],
  authlist:[],
  workshop2:[],
  gallery:[],
  recipesR:[],
  subProject:[],
  lessonstime:[],
  classattendance:[],
  classes:[],
  
  dynamicformextra:[],
  dynamicformextraextra:[],
  recipesReview:[],
  mainPageSlider:[],
  certifica:[],
  productCategory:[],
  recipesCategory:[],
  products:[],

}

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case ADD_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: [...state.products, action.payload],
      }

    case UPDATE_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: state.products.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
      }

    case UPDATE_CERTIFICA_SUCCESS:
      return {
        ...state,
        certifica: state.certifica.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }
      
      case DELETE_RECIPES_SUCCESS:
        return {
          ...state,
          recipesR: state.recipesR.filter(
            user => user.ID.toString() !== action.payload.toString()
          ),
        }

      case DELETE_RECIPES_CATEGORY_SUCCESS:
        return {
          ...state,
          recipesCategory: state.recipesCategory.filter(
            user => user.ID.toString() !== action.payload.toString()
          ),
        }


      case DELETE_CERTIFICA_SUCCESS:
        return {
          ...state,
          certifica: state.certifica.filter(
            user => user.ID.toString() !== action.payload.toString()
          ),
        }

    case ADD_RECIPESCATEGORY_SUCCESS:
      return {
        ...state,
        recipesCategory: [...state.recipesCategory, action.payload],
      }

      case UPDATE_RECIPESCATEGORY_SUCCESS:
        return {
          ...state,
          recipesCategory: state.recipesCategory.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }

        case GET_RECIPESCATEGORY_SUCCESS:
          return {
            ...state,
            recipesCategory: action.payload,
          }

    case UPDATE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        productCategory: state.productCategory.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }

    case ADD_PRODUCTCATEGORY_SUCCESS:
      return {
        ...state,
        productCategory: [...state.productCategory, action.payload],
      }
    
    case GET_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        productCategory: action.payload,
      }

    case DELETE_PROJECTS_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }

    case DELETE_MAINSLIDER_SUCCESS:
      return {
        ...state,
        mainPageSlider: state.mainPageSlider.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }

    case ADD_MAINSLIDER_SUCCESS:
      return {
        ...state,
        mainPageSlider: [...state.mainPageSlider, action.payload],
      }

      case GET_RECIPES_SUCCESS:
        return {
          ...state,
          recipesR: action.payload,
        }

      case ADD_RECIPES_SUCCESS:
        return {
          ...state,
          recipesR: [...state.recipesR, action.payload],
        }
      
    
    case UPDATE_MAINSLIDER_SUCCESS:
      return {
        ...state,
        mainPageSlider: state.mainPageSlider.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }

      case UPDATE_RECIPES_SUCCESS:
        return {
          ...state,
          recipesR: state.recipesR.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }
  

    case UPDATE_REVIEW_SUCCESS:
      return {
        ...state,
        recipesReview: state.recipesReview.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }
      
    case ADD_CERTIFICA_SUCCESS:
      return {
        ...state,
        certifica: [...state.certifica, action.payload],
      }

    case GET_CERTIFICA_SUCCESS:
      return {
        ...state,
        certifica: action.payload,
      }

    case GET_MAINPAGESLIDER_SUCCESS:
      return {
        ...state,
        mainPageSlider: action.payload,
      }


    case DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextraextra: state.dynamicformextraextra.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_DYNAMICFORM_SUCCESS:
      return {
        ...state,
        dynamic: state.dynamic.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }
    
    case DELETE_DYNAMICFORMEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextra: state.dynamicformextra.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }


       case UPDATE_USER_SUCCESS:
        return {
          ...state,
          users: state.users.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }

        case UPDATE_DYNAMICFORM_SUCCESS:
          return {
            ...state,
            dynamic: state.dynamic.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }
          
          case DELETE_PRODUCTCATEGORY_SUCCESS:
            return {
              ...state,
              productCategory: state.productCategory.filter(
                forum => forum.ID.toString() !== action.payload.toString()
              ),
            }

          case DELETE_USER_SUCCESS:
            return {
              ...state,
              users: state.users.filter(
                forum => forum.ID.toString() !== action.payload.toString()
              ),
            }

    case DELETE_COURSESCHEDULES_SUCCESS:
      return {
        ...state,
        workshopSession: state.workshopSession.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        gallery: state.gallery.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }

      
      case ADD_SLIDER_SUCCESS:
        return {
          ...state,
          slider: [...state.slider, action.payload],
        }

    case ADD_GALLERY_SUCCESS:
      return {
        ...state,
        gallery: [...state.gallery, action.payload],
      }



    case DELETE_POPUP_SUCCESS:
      return {
        ...state,
        popup: state.popup.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_SLIDER_SUCCESS:
      return {
        ...state,
        slider: state.slider.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }


      
    case ADD_NEWS_SUCCESS:
      return {
        ...state,
        news: [...state.news, action.payload],
      }

    case DELETE_NEWS_SUCCESS:
      return {
        ...state,
        news: state.news.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_MEGA_SUCCESS:
        return {
          ...state,
          mega: state.mega.filter(
            user => user.ID.toString() !== action.ID.toString()
          ),
        }
  

    case ADD_MEGA_SUCCESS:
      return {
        ...state,
        mega: [...state.mega, action.payload],
      }

      
      case GET_RECIPES_REVIEW_SUCCESS:
        return {
          ...state,
          recipesReview: action.payload,
        }

    case GET_DYNAMICFORMEXTRAEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextraextra: action.payload,
      }

    case GET_DYNAMICFORMEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextra: action.payload,
      }

    case UPDATE_DYNAMICFORM_SUCCESS:
      return {
        ...state,
        dynamic: state.dynamic.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case GET_CLASSES_SUCCESS:
      return {
        ...state,
        classes: action.payload,
      }

    case GET_CLASSATTENDANCE_SUCCESS:
      return {
        ...state,
        classattendance: action.payload,
      }

    case UPDATE_LESSONSTIME_SUCCESS:
      return {
        ...state,
        lessonstime: state.lessonstime.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }
      
    case ADD_DYNAMICFORM_SUCCESS:
        return {
          ...state,
          dynamic: [...state.dynamic, action.payload],
        }

    case ADD_LESSONSTIME_SUCCESS:
      return {
        ...state,
        lessonstime: [...state.lessonstime, action.payload],
      }

      case GET_TEACHERWORKSHOP_SUCCESS:
        return {
          ...state,
          lessonstime: action.payload,
        }

        
    case GET_SUBPROJECT_SUCCESS:
      return {
        ...state,
        subProject: action.payload,
      }


         
    case GET_AUTHUPDATE_SUCCESS:
      return {
        ...state,
        authlist: [],
      }

    case GET_AUTH_SUCCESS:
      return {
        ...state,
        authlist: action.payload,
      }
      
      case ADD_WORKSHOP2_SUCCESS:
        return {
          ...state,
          workshop2: [...state.workshop2, action.payload],
        }

        case DELETE_WORKSHOP2_SUCCESS:
          return {
            ...state,
            workshop2: state.workshop2.filter(
              user => user.ID.toString() !== action.payload.toString()
            ),
          }

          

      case GET_WORKSHOP2_SUCCESS:
        return {
          ...state,
          workshop2: action.payload,
        }

    case GET_CONTACT:
      return {
        ...state,
        contact: action.payload,
      }

    case GET_DYNAMIC_SUCCESS:
      return {
        ...state,
        dynamic: action.payload,
      }

    case GET_TEACHER_SUCCESS:
      return {
        ...state,
        teacher: action.payload,
      }
    case GET_MEGA_SUCCESS:
      return {
        ...state,
        mega: action.payload,
      }

    case GET_NEWS_SUCCESS:
      return {
        ...state,
        news: action.payload,
      }

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      }

    case GET_SLIDER_SUCCESS:
      return {
        ...state,
        slider: action.payload,
      }
    case GET_APPLICATION_SUCCESS:
      return {
        ...state,
        workshopsessionMega: action.payload,
      }
    case GET_WORKSHOPSESSION_SUCCESS:
      return {
        ...state,
        workshopSession: action.payload,
      }
    case GET_WORKSHOP_SUCCESS:
      return {
        ...state,
        workshop: action.payload,
      }

      case GET_GALLERY_SUCCESS:
        return {
          ...state,
          gallery: action.payload,
        }

        
        case UPDATE_SUCCESS_GALLERY:
          console.log("deneme123",action.payload)
          return {
            ...state,
            gallery: state.gallery.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }
      
          
      case UPDATE_SUCCESS_MEGA:
        console.log("deneme123",action.payload)
        return {
          ...state,
          mega: state.mega.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }
      
        
                
      case UPDATE_WORKSHOP2_SUCCESS:
        return {
          ...state,
          workshop2: state.workshop2.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

      case UPDATE_SUCCESS_NEWS:
        return {
          ...state,
          news: state.news.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }


      case UPDATE_SUCCESS_PROJECT:
        return {
          ...state,
          projects: state.projects.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

       case UPDATE_SUCCESS_SLIDER:
        return {
          ...state,
          slider: state.slider.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

      case UPDATE_SUBDOMAIN_SUCCESS:
        return {
          ...state,
          workshop: state.workshop.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

        
        case UPDATE_SUCCESS_TEACHER:
          return {
            ...state,
            teacher: state.teacher.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }


        case UPDATE_WORKSHOPSESSION_SUCCESS:
          return {
            ...state,
            workshopsessionMega: state.workshopsessionMega.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }

        case UPDATE_SUCCESS_COURSESCHEDULES:
          console.log("lksdnfsd",state.workshopSession)
          return {
            ...state,
            workshopSession: state.workshopSession.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }

     case UPDATE_POPUP_SUCCESS:
        return {
          ...state,
          popup:  state.popup.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }
        
    case GET_POPUP_SUCCESS:
      return {
        ...state,
        popup:  action.payload,
      }

    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case ADD_USER_SUCCESS:

      return {
        ...state,
        users: [...state.users, action.payload],
      }

      case UPDATE_USER_SUCCESS:
        return {
          ...state,
          users: state.users.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      }


  
      case UPDATE_USER_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  

  
      case DELETE_USER_FAIL:
        return {
          ...state,
          error: action.payload,
        }

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default contacts
