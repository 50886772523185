import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
  addReferances as onAddReferances,
  updateRecipesReview as onUpdateRecipesReview,
  getRecipesReview as onGetRecipesReview,
  deleteRecipesReview as onDeleteRecipesReview
} from "store/contacts/actions";

import {
  Name,
  Date,
  Status
}
  from "./EcommerceOrderCol";
  import 'react-dropzone-uploader/dist/styles.css'

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Card,
  CardBody,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function EcommerceOrder() {

  //meta title
  document.title = "Tarif Yorumları | Buhara - Admin Paneli";


  const [getSelectProduct, setSelectProduct] = useState([]);

  const [getSelectGramList, setSelectGramList] = useState([]);

  const [getIsActive, setIsActive] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);


  const [getEditSlider, setEditSlider] = useState(null);


  const validation2 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      units: (getEditSlider && getEditSlider.Products_Unit_Input) || '',
    },
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateSlider = {
          ID: order ? order.ID : 0,
          IsActive: getIsActive
        };
        // update order
        dispatch(onUpdateRecipesReview(updateSlider));
        validation.resetForm();
      } else {
        const newOrder = {
          IsActive:getIsActive
        };
        console.log("newOrder123",newOrder)
        // save new order
        dispatch(onAddReferances(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });
  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
    },
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateSlider = {
          ID: order ? order.ID : 0,
          IsActive: getIsActive
        };
        // update order
        dispatch(onUpdateRecipesReview(updateSlider));
        validation.resetForm();
      } else {
        const newOrder = {
          IsActive: getIsActive,
        };
        console.log("newOrder123",newOrder)
        // save new order
        dispatch(onAddRecipesReview(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { orders } = useSelector(state => ({
    orders: state.contacts.recipesReview,
  }));

  useEffect(() => {
   if (orders && !orders.length) {
      dispatch(onGetRecipesReview());
      setIsEdit(false);
    }
  }, [dispatch, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);



  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };




  const handleOrderClick = arg => {
    const order = arg;
    setEditSlider(order)
    setOrder(order);
    setIsEdit(true);

    setIsActive(order.IsActive)
    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.ID) {
      dispatch(onDeleteRecipesReview(order.ID));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Tarif',
        accessor: 'Recipes.Recipes_Title',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },

      {
        Header: "Tarif Görseli",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.Recipes.Recipes_Image ? (
              <div className="avatar-xl">
               {cellProps.Recipes.Recipes_Title != null ? <span className="avatar-title rounded-circle">
                  {cellProps.Recipes_Title.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className=" avatar-xl"
                  src={'https://buharasubdomain.stechomeyazilim.info/' +  cellProps.Recipes.Recipes_Image}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: 'Puan',
        accessor: 'Recipes_Rewies_Star',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },

      {
        Header: 'Kul. Adı',
        accessor: 'Users.NameSurname',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        Header: 'Mail Adres',
        accessor: 'Recipes_Rewies_MailAdress',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },

      {
        Header: 'Aktiflik Durumu',
        accessor: 'IsActive',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },

      {
        Header: 'Tarih',
        accessor: 'Recipes_Rewies_CreatedDate',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      
          
      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
            
             

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  function showToast(type) {
    console.log("type123",type)
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Giriş İşleminiz Gerçekleşmiştir!"
    } else{
       ele = "error"
       message = "Gramaj Eklemeniz için Ürün Eklemelisiniz!"
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  const handleChangeGramList= async (val,type) => {
    if(getSelectProduct.length > getSelectGramList.length){
      setSelectGramList(val)
    }else{
      showToast(false)
    }
           
    }


  
    
  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Yönetim" breadcrumbItem="Tarif Yorumları" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    isAddRecipes={false}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          


          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Yorumu Düzenle" : "Yorumu Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                
                <div className="d-flex">
                          <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                        
                       
                        </div>


                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;